import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate, Link } from "react-router-dom";
import ProfilePic from "../../assets/images/profile.png";
import LogoOdisha from "../../assets/images/Odisha-sasan.png";
import Image from "react-bootstrap/Image";
import "../private/PrivateHeader.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getApplications, postData } from "../../services/http.service";
import { useTranslation } from "react-i18next";
import { useLanguageContext } from "../../LanguageContext";
// import { searchApplication } from "../../services/http.service";
import { useEffect, useState } from "react";
import { URLS_ENDPOINT } from "../../utils/urls.const";
import { APP_MESSAGES } from "../../utils/constants";
import { Button } from "react-bootstrap";

const PrivateHeader = () => {
  const { t } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  let navigate = useNavigate();
  const [exisitingApplication, setExisitingApplication] = useState(false);
  // const onLogout = () => {
  //     // localStorage.removeItem('token');
  //     // navigate("/mission-shakti/")
  //     localStorage.removeItem('token');
  //     toast.info('Logged out successfully!', {
  //         position: "top-right"
  //     });
  //     setTimeout(() => {
  //         navigate("/mission-shakti/");
  //     }, 1000);
  // }
  useEffect(() => {
    const token: string | null = localStorage.getItem('token');
    const userInfo = JSON.parse(localStorage.getItem('UserDetails') || '{}');

    const dataFetch = async () => {
      if (token && userInfo) {
        const response = await getApplications(token, {
          // userInfo
          uuid: userInfo.uuid,
          rolesCode: userInfo.roles[0].roleName,
        });
        if (response.length >= 1) {
          setExisitingApplication(true)
        }
      }
    };
    dataFetch();
  }, []);
  const onLogout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const URL = `${process.env.REACT_APP_USER_SERVICE}`+ URLS_ENDPOINT.USER_SERVICE.LOGOUT;
        const res = await postData(URL, {}, {
          headers: {
            Authorization: token,
          },
        });
        if(res.status === 200){
          localStorage.clear();
          toast.info(APP_MESSAGES.LOGOUT_MSG, {
            position: "top-right",
          });
           setTimeout(() => {
             navigate("/mission-shakti");
          },1000);
        }else{
          toast.error(APP_MESSAGES.LOGOUT_ERR, {
            position: "top-right",
          });
        }    
        // await userLogout(token);
        // localStorage.removeItem("token");
      } catch (error) {
        toast.error(APP_MESSAGES.LOGOUT_ERR, {
          position: "top-right",
        });
      }
    } else {
      toast.error("No token found. Please login again.", {
        position: "top-right",
      });
    }
  };
  const role = localStorage.getItem("role");
  const uuid = localStorage.getItem("UUID");

  return (
    <>
      <div>
        <Navbar expand="lg" bg="white" className="private-navbar">
          <Container>
            <img src={LogoOdisha} className="me-3" style={{ height: "80px" }} />
            <Navbar.Brand
              href="#home"
              className="lh-sm-2"
              style={{ fontFamily: "serif" }}
            >
              <span className="fw-bold">
                {t("PrivateHeader.Mission Shakti")}
              </span>
              <div className="fw-bold">{t("PrivateHeader.Scooter Yojana")}</div>
              <div style={{ fontSize: "15px" }}>
                {t("PrivateHeader.Goverment of Odisha")}
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="basic-navbar-nav">
              <Nav className="me-auto">
                {/* <Nav.Link as={Link} to="/mission-shakti/home">
                  {t("PrivateHeader.Home")}
                </Nav.Link> */}

                {role === "FL/CSS" && (
                  <>
                    <NavDropdown
                      title={t("PrivateHeader.My Application")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item as={Link} to="/mission-shakti/home">
                      {t("PrivateHeader.Home")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/mmsy_loan_form"
                        disabled={exisitingApplication}
                      >
                        {t("PrivateHeader.ApplyforMSSYLoan")}
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        {t("PrivateHeader.ViewApplication")}
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        {t("PrivateHeader.DownloadApplicationForm")}
                      </NavDropdown.Item>
                      {/* <NavDropdown.Item
                      as={Link}
                      to="/mission-shakti/track-my-application"
                    >
                      {t("PrivateHeader.Track Applications")}
                    </NavDropdown.Item> */}

                      {/* Add more menu items as needed */}
                    </NavDropdown>
                  </>
                )}
                {role === "BPC" && (
                  <>
                    <Nav.Link as={Link} to="/mission-shakti/home">
                      {t("PrivateHeader.Home")}
                    </Nav.Link>

                    <NavDropdown
                      title={t("PrivateHeader.Application Management")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/applications"
                      >
                        {t("PrivateHeader.Beneficiary Applications")}
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title={t("Master")} id="basic-nav-dropdown">
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/masters/shgdetails"
                      >
                        {t("SHG Details")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {role === "CDPO" && (
                  <>
                    <Nav.Link as={Link} to="/mission-shakti/home">
                      {t("PrivateHeader.Home")}
                    </Nav.Link>

                    <NavDropdown
                      title={t("PrivateHeader.Application Management")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/applications"
                      >
                        {t("PrivateHeader.Beneficiary Applications")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {role === "BDO" && (
                  <>
                    <Nav.Link as={Link} to="/mission-shakti/home">
                      {t("PrivateHeader.Home")}
                    </Nav.Link>

                    <NavDropdown
                      title={t("PrivateHeader.Application Management")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/applications"
                      >
                        {t("PrivateHeader.Beneficiary Applications")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">
                        {t("PrivateHeader.Check Eligibility")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">
                        {t("PrivateHeader.Issue Recommendation Certificates")}
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">
                        {t("PrivateHeader.Forward Applications")}
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/track-my-application"
                      >
                        Track Applications
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
                {role === "BRANCH_BANK" && (
                  <Nav.Link as={Link} to="/mission-shakti/loan-bank/home">
                    {t("PrivateHeader.Home")}
                  </Nav.Link>
                )}
                {role === "CONTROLLING_BANK" && (
                  <Nav.Link as={Link} to="/mission-shakti/head-bank/home">
                    {t("PrivateHeader.Home")}
                  </Nav.Link>
                )}

                {role === "ADMIN" && (
                  <>
                    <Nav.Link as={Link} to="/mission-shakti/home">
                      {t("PrivateHeader.Home")}
                    </Nav.Link>

                    <NavDropdown title={t("Master")} id="basic-nav-dropdown">
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/masters/user-management"
                      >
                        {t("User Management")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/masters/shgdetails"
                      >
                        {t("SHG Details")}
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown
                      title={t("Table.SUBVENTION_TABLE_HEADER")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/interestsubvention/home"
                      >
                        {t("Calculations")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/interestsubvention/reports"
                      >
                        {t("Reports")}
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown
                      title={t("Table.Others")}
                      id="basic-nav-dropdown"
                    >
                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/masters/news&update"
                      >
                        {t("Table.News")}
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        as={Link}
                        to="/mission-shakti/others/view-feebacks"
                      >
                        {t("feedback.feedback")}
                      </NavDropdown.Item>
                    </NavDropdown>
                  </>
                )}
              </Nav>

              <Nav className="ml-auto">
                <NavDropdown
                  title={
                    <img
                      src={ProfilePic}
                      height="40"
                      width="40"
                      className="mr-2 profile-nav-img"
                      alt="Profile"
                    />
                  }
                  id="profile-nav-dropdown"
                >
                  {/* <Image
                    src={ProfilePic}
                    roundedCircle
                    height="30"
                    width="30"
                    className="mr-2"
                    alt="Profile"
                  /> */}
                  <NavDropdown.Item
                    as={Link}
                    to={`/mission-shakti/user-profile/ViewProfile/${uuid}`}
                  >
                    {t("PrivateHeader.My Profile")}
                  </NavDropdown.Item>
                  {/* <NavDropdown.Item
                    as={Link}
                    to={`/mission-shakti/user-profile/EditProfile/${uuid}`}
                  >
                    {t("PrivateHeader.Edit Profile")}
                  </NavDropdown.Item> */}
                  <NavDropdown.Item
                    as={Link}
                    to="/mission-shakti/user-profile/ChangePassword"
                  >
                    {t("PrivateHeader.Change Password")}
                  </NavDropdown.Item>
                  {/* <NavDropdown.Divider /> */}
                  <NavDropdown.Item onClick={onLogout}>
                    {t("PrivateHeader.Logout")}
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
              <Nav className="ml-auto">
                <NavDropdown title={t("Language")} id="language-nav-dropdown">
                  <NavDropdown.Item
                    onClick={() => changeLanguage("en")}
                    className="small-text"
                  >
                    English
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => changeLanguage("or")}
                    className="small-text"
                  >
                    ଓଡ଼ିଆ
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <ToastContainer />
    </>
  );
};

export default PrivateHeader;
