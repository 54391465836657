import React, { useEffect, useState } from 'react';
import { Container, Navbar } from 'react-bootstrap';
import BannerScooter from '../../assets/images/slider-1-1.jpg'
import BannerScooter1 from '../../assets/images/slider-2.jpg'
import BannerScooter2 from '../../assets/images/slider-3.jpg'
import BannerScooter3 from '../../assets/images/Mission-Shakti-Scooter-Yojana1.png'
import { Carousel } from "react-bootstrap";
import BannerBike from "../../assets/images/Banner-Illustration-Bike.png";
import TitleDecore from "../../assets/images/Title-Decore.png";
import ButtonIconScooter from "../../assets/images/Button-ic1.png"
import ApplyLoanIcon from "../../assets/images/applyloan.png"
import BlankInage from "../../assets/images/blankImage.png";
import PravabatiP from "../../assets/images/pravavatiParida.jpg";
import ShaliniP from "../../assets/images/shaliniPandit.jpg";
import vinnetB from "../../assets/images/vinnetB.jpg"
import ScooterYojana2 from "../../assets/images/scootrYojana2.jpeg";
import Pic_1 from "../../assets/images/scooterImage4.jpg";
import Image32 from "../../assets/images/image_32.png";
import Image33 from "../../assets/images/image_33.png";
import Image34 from "../../assets/images/image_34.png";
import Image35 from "../../assets/images/image_35.png";
import ActionModal from '../common/ActionModal';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../services/http.service';

const LoanEligibilityList:any = [
    "Must be a certified SHG Community Leader and Federation Support Staff",
    "Should be in the age range of 18-60 years",
    "Should have a Aadhaar No",
    "Should have PAN Card", 
    "Should have SHG ID",
    "Should have mobile No linked with Aadhaar",
    "Should have PAN card linked with Aadhaar"

];
const documentsRequiredList:any = [{
        name: "Identity Proof: Self certified copies of -",
        values: ["Aadhaar Card","Voter’s ID Card","Driving License","PAN Card","Passport"]
    },
    {
        name: "Address/Residence Proof- Self attested copies of -",
        values: ["Telephone Bill","Electric Bill","Property Tax Receipt (not older than 2 months)","Voter’s ID Card"]
    },
    {
        name: "Recent photos (2 copies)"
    },
    {
        name: "Quotation details (from dealer/Seller)"
    },
    {
        name: "Should have mobile No linked with Aadhaar"
    },
    {
        name: "Should have PAN card linked with Aadhaar"
    }]

const PublicBanner = () => {
    const [modal, setModal] = useState(false);
    const [modalAction, setModalAction] = useState("");
    const [modalTitle, setModalTitle] = useState("");
    const [Banks, setBanks] = useState([]);
    let navigate = useNavigate();

    useEffect(() => {
        async function loadBanks() {
          try {
            const response = await postData(
              process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
              {
                userInfo: {},
                "bankFilterDetails": {
                    "bankCode": "",
                    "branchCode": ""
                }
              }
            );
            // const response = await fetchBankList();
            const bankList = response.data.masterBankDetails || []; // Ensure banks array is extracted from the response
            let newone:any = [];
            bankList.sort((a:any,b:any)=> (a.bankName < b.bankName)? -1 : 1)
            for (const key in bankList) {
              newone.push({ value: bankList[key]?.code, label: bankList[key]?.bankName });
            }
            setBanks(newone);
          } catch (error) {
            console.error("Error fetching bank list:", error);
          }
        }
        loadBanks();
        
      }, []);

    const onClickLoanProcess = (name:any) =>{
        if(name !== 'Loan/registry') {
            setModalTitle(name);
            setModal(!modal)
        } else {
            navigate("/mission-shakti/login");
        }
        
    };
    const Toggle = () => {
        setModal(!modal);
    };
    const handleModalSubmit = (e:any)=> {
        // setModalAction(e)
        // handleFormSubmit(shgFormData)
    };

    const LoanProcess = () => {
            console.log("modalTitle===",modalTitle,Banks)
        return (
          <>
            {modalTitle==="Empanelled Banks" && (<div>
                <ul>
                    {Banks?.length>0 && Banks.map((e:any,i)=> (
                        <li key={i} style={{listStyle: "auto"}}>
                            {e?.label}
                        </li>
                    ))}
                </ul>
            </div>)}
            {modalTitle==="Loan Eligibility" && (<div>
                <ul>
                    {LoanEligibilityList?.length>0 && LoanEligibilityList.map((e:any,i:any)=> (
                        <li key={i} style={{listStyle: "auto"}}>
                            {e}
                        </li>
                    ))}
                </ul>
            </div>)}
            {modalTitle==="Document Required" && (<div>
                <ul>
                    {documentsRequiredList?.length>0 && documentsRequiredList.map((e:any,i:any)=> (
                        <li key={i} style={{listStyle: "auto"}}>
                            {e.name}
                            {e?.values && e?.values.length>0 && (
                                <ul>
                                    {e?.values.map((val:any,valIdx:any)=>(
                                        <li key={valIdx} style={{listStyle: "outside"}}>
                                            {val}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </div>)}
          </>
        );
      };

    return (
        <>
            <div style={{  }} className="">
            <div className='row banner-cls' style={{paddingRight: 0}}>
                <div className='col-sm-6' style={{paddingTop: "50px",position: "relative", left: "85px"}}>
                    <h6 className='schemename-cls'>Mission Shakti Scooter Yojana</h6>
                    <div className='banner-txt'>
                        <p>Mobility is Empowerment<img src={TitleDecore} className='title-decore1'></img></p>
                        {/* <p>Empowered</p>
                        <p>Women</p> */}
                    </div>
                    <div style={{marginTop: 42}}>
                        <span className='check-eligibility'> <span className='ButtonIconScooter-span'><img src={ButtonIconScooter} style={{height: "25px"}} /></span> Check Scheme Eligibility</span>
                        <span className='apply-scooterloan'><span className='applyloanicon-span'><img src={ApplyLoanIcon} style={{height: "25px"}} /></span>Apply Scooter Loan</span>
                    </div>
                </div>
                <div className='col-sm-6'>
                    <img src={BannerBike} alt="Banner Bike" style={{ width: "100%"}} />
                </div>
            </div>
            <div className='container' style={{marginTop: "40px"}}>
                <div className='row'>
                    <div className='col-sm-4'>
                        <div className='img-cls1'>
                            <img className='blank-img' src={PravabatiP} alt='Blank' />
                            <div className='placeholder-txt'>
                                <div className='placeholder-name'>Smt. Pravati Parida</div>
                                <div>Hon'ble Deputy Chief Minister</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4'>
                        <div className='img-cls1'>
                            <img className='blank-img' src={ShaliniP} alt='Blank' />
                            <div className='placeholder-txt'>
                                <div className='placeholder-name'>Smt. Shalini Pandit, IAS</div>
                                <div>Commissioner-Cum-Secretary</div>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-4' style={{marginRight: 0}}>
                        <div className='img-cls1'>
                            <img className='blank-img' src={vinnetB} alt='Blank' />
                            <div className='placeholder-txt'>
                                <div className='placeholder-name'>Shri Vineet Bhardwaj, IAS</div>
                                <div>State Mission Director cum CEO</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row' style={{marginTop: "20px"}}>
                    <div className='col-sm-6' style={{paddingTop: "5%"}}>
                        <div style={{fontSize: 45, fontWeight: 700}}>About Scooter Yojana</div>
                        <img style={{height: "10px", display: "block"}} src={TitleDecore} alt='Blank' />
                        <p style={{fontSize: 18, lineHeight: "32px"}}>
                        In recognition of the contribution of the Community Leaders and Support, the Department of Mission Shakti will facilitate full interest subvention on bank loans up to Rs 1 lakh to eligible women to purchase scooters. While 40,000+ women have already been handed over keys to scooters of their choice, 35,000+ are gearing up for the same.
                        </p>
                    </div>
                    <div className='col-sm-6' style={{paddingTop: "5%"}}>
                        <span className='faded faded-left'>
                            <img className='scooteryojana-img' src={Pic_1} alt='Scooter Yojana' />
                        </span>
                    </div>
                   
                </div>
                <div>
                    <div style={{fontSize: 45, fontWeight: 700, textAlign: "center"}}>
                        Scooter Loan Process
                        <img style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: "35%"}} src={TitleDecore} alt='Blank' />
                    </div>
                </div>
                <div style={{marginTop: 20}} className='row'>
                    <div className='col-sm-3 features-card' onClick={(e)=>onClickLoanProcess('Loan/registry')}>
                        <img style={{paddingBottom: 10}} src={Image32} alt="Loan/registry"  />
                        <div>
                            <div className='features-card-txt-h'>Login/ Register</div>
                            <p>Take the first step towards mobility and independence.</p>
                        </div>
                    </div>
                    <div className='col-sm-3 features-card' onClick={(e)=>onClickLoanProcess('Empanelled Banks')}>
                        <img style={{paddingBottom: 10}} src={Image33} alt="Empanelled Banks"  />
                        <div>
                            <div className='features-card-txt-h'>Empanelled Banks</div>
                            <p>{Banks?.length} department enabled banks as trusted partners to facilitate the beneficiaries to avail hassle-free Scooter loan.</p>
                        </div>
                    </div>
                    <div className='col-sm-3 features-card' onClick={(e)=>onClickLoanProcess('Loan Eligibility')}>
                        <img style={{paddingBottom: 10}} src={Image34} alt="Loan Eligibility"  />
                        <div>
                            <div className='features-card-txt-h'>Loan Eligibility</div>
                            <p>Check your loan eligibility before you rush to get your scooter.</p>
                        </div>
                    </div>
                    <div className='col-sm-3 features-card' onClick={(e)=>onClickLoanProcess('Document Required')}>
                        <img style={{paddingBottom: 10}} src={Image35} alt="Document Required"  />
                        <div>
                            <div className='features-card-txt-h'>Document Required</div>
                            <p>Keep these documents handy as you move a step closer to get your scooter.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Carousel>
                <Carousel.Item style={{ height: '65vh' }}>
                    <img
                        className="d-block w-100"
                        src={BannerScooter}
                        alt="First slide"
                        id='img1'
                        style={{ height: '100%', objectFit: 'fill' }}
                    />
                </Carousel.Item>
                <Carousel.Item style={{ height: '65vh' }}>
                    <img
                        className="d-block w-100"
                        src={BannerScooter1}
                        alt="Second slide"
                        id='img1'
                        style={{ height: '100%', objectFit: 'fill' }}
                    />
                </Carousel.Item>
                <Carousel.Item style={{ height: '65vh' }}>
                    <img
                        className="d-block w-100"
                        src={BannerScooter2}
                        alt="Third slide"
                        id='img1'
                        style={{ height: '100%', objectFit: 'fill' }}
                    />
                </Carousel.Item>
                <Carousel.Item style={{ height: '65vh' }}>
                    <img
                        className="d-block w-100"
                        src={BannerScooter3}
                        alt="Third slide"
                        id='img1'
                        style={{ height: '100%', objectFit: 'fill' }}
                    />
                </Carousel.Item>
            </Carousel> */}
        </div>
        <ActionModal
            show={modal}
            close={Toggle}
            handleModalSubmit={handleModalSubmit}
            title={modalTitle}
            action={modalAction}
            isFooterHide = {true}
            size={"lg"}
          >
            <div>
                <LoanProcess />
            </div>
          </ActionModal>
        </>
        
    );
};

export default PublicBanner;
