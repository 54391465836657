import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Row, Col } from 'react-bootstrap';
import '../../../src/components/public/PublicFooter.css'
import FacebooIcon from '../../assets/images/FacebookIcon.png'
import Twitter from '../../assets/images/twitter.png'
import Playstore from '../../assets/images/Playstore.png';
import AddressIcon from '../../assets/images/address.png'
import EmailIcon from '../../assets/images/email.png'
import PhoneIcon from '../../assets/images/phone.png';

import { FaInfoCircle } from "react-icons/fa";
import { IoLocationOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa6";
import { SlSocialInstagram } from "react-icons/sl";
import { IoLogoYoutube } from "react-icons/io";
import { FaTwitter } from "react-icons/fa";
import { IoEye } from "react-icons/io5";




import { BiCommentDetail, BiEnvelope, BiLogoFacebook, BiLogoTwitter } from "react-icons/bi";
import { useEffect, useState } from 'react';
import { postData } from '../../services/http.service';
import { toast, ToastContainer } from 'react-toastify';
import { APP_MESSAGES } from '../../utils/constants';
import { URLS_ENDPOINT } from '../../utils/urls.const';
import ActionModal from '../common/ActionModal';
import FeedbackPage from './FeedbackPage';
import WebsitePolicyPage from './WebsitePolicyPage';
import FaqPage from './FaqPage';

const feedbackFormData:any = {
    "uuid": "",
    "firstName": "",
    "lastName": "",
    "email": "",
    "phone": "",
    "subject": "",
    "feedback": ""
  }

const PublicFooter = () => {
    const [visitorCount, setVisitorCount] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalAction, setModalAction] = useState("create");
    const [feedFormData, setFeedFormData] = useState(feedbackFormData);
    const [modalTitle, setModalTitle] = useState("Add SHG Details");
    useEffect(()=>{
        const getVisitosCount = async () => {
          try {
            let URL  = process.env.REACT_APP_MASTER_SERVICE+URLS_ENDPOINT.MASTER_SERVICES.UTILS_VISITOR_COUNT;
            const response: any = await postData(
              URL,
              {
               
              }
            );
    
            if (response?.data) {
                let cnt:any = response.data.split(":");
                if(cnt && cnt.length>0)
                setVisitorCount(cnt[1])
            }
          } catch (error) {
            toast.error(APP_MESSAGES.CMN_ERR, {
              position: "top-right",
            });
          }
        };
        getVisitosCount();
      },[]);

    const handleModalSubmit = (e:any) => {

    };
    const Toggle = () => {
        setModal(!modal);
    };
    const handleFormSubmit = async (e: any) => {
      if (e) {
        try {
          const URL =  process.env.REACT_APP_MIS_APP_SERVICE + URLS_ENDPOINT.APP_SERVICES.SAVE_FEEDBACK;
          const response: any = await postData(URL, {
            userInfo: {},
            feedback: { ...e },
          });
          console.log(response);
          if (response?.data) {
            toast.success("Feedback submitted successfully!", {
              position: "top-right",
            });
            Toggle();
          }
        } catch (error) {
          toast.error(APP_MESSAGES.CMN_ERR, {
            position: "top-right",
          });
        }
      }
    };
    const onClickImportantLink = (e:any)=> {
        console.log("---",e)
        setModalTitle(e)
        setModal(!modal);
    }

    return (
        <>
          <ToastContainer />
            <div className="footer mt-auto py-3">
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-2'>
                            <div className='footer-links'>Important links</div>
                            <ul className='footer-important-links'>
                                <li>Home</li>
                                <li onClick={e=>onClickImportantLink("FAQ")} style={{cursor: "pointer"}}>FAQ</li>
                                <li onClick={e=>onClickImportantLink("Feedback")} style={{cursor: "pointer"}}>Feedback</li>
                                {/* <li>Disclaimer</li> */}
                                <li onClick={e=>onClickImportantLink("Website Policy")} style={{cursor: "pointer"}}>Website Policy</li>
                            </ul>
                        </div>
                        <div className='col-sm-8'>
                            <div className='footer-links'>Contact Us</div>
                            <div className='row more-links'>
                                <div className='col-sm-4'>
                                    <ul className='footer-important-links'>
                                        <li> <IoLocationOutline className='contact-us'/> 
                                        <p>Mission Shakti Bhavan,HIG-109, Jagamara - Sundarpada Rd, Gandamunda, Pokhariput, Bhubaneswar, Odisha 751030</p></li>
                                        {/* <li> <div>Email</div></li>
                                        <li> <div>Phone</div></li> */}
                                    </ul>
                                </div>
                                <div className='col-sm-4'>
                                    <ul className='footer-important-links'>
                                        <li ><a href="mailto:missionshakti.od@gov.in" style={{ textDecoration: "none", color: "white"}}><TfiEmail className='contact-us'/> 
                                            <p>missionshakti.od@gov.in</p></a></li>
                                        {/* <li>Feedback</li>
                                        <li>About Mission Shakti</li> */}
                                    </ul>
                                </div>
                                <div className='col-sm-4'>
                                    <ul className='footer-important-links'>
                                        <li><a href="tell:0674-2974093" style={{ textDecoration: "none", color: "white"}}><FaPhone className='contact-us'/> <p>0674-2974093</p></a></li>
                                        {/* <li>Feedback</li>
                                        <li>About Mission Shakti</li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-2'>
                            <div className='footer-links'>Social Handle</div>
                            <div className='row more-links'>
                                <div className='col-sm-3'>
                                    <ul className='footer-important-links'>
                                        <li style={{background: "#ffffff", width: "fit-content", borderRadius: "3rem"}}> <a rel="noreferrer" href="https://www.facebook.com/missionshakti.odisha/" target="_blank"><FaFacebook style={{border: "none", color: "#0058ff"}} className='contact-us'/></a></li>
                                        
                                    </ul>
                                </div>
                                <div className='col-sm-3'>
                                    <ul className='footer-important-links'>
                                        <li> <a rel="noreferrer" href="https://www.instagram.com/accounts/login/?next=https%3A%2F%2Fwww.instagram.com%2Fmissionshaktiodisha%2F&is_from_rle" target="_blank"> <div className='insta-icon'/> </a></li>
                                    </ul>
                                </div>
                                <div className='col-sm-3'>
                                    <ul className='footer-important-links'>
                                        <li><a rel="noreferrer" href="https://www.youtube.com/channel/UCjE0cVtgR5ONmqwa5kBgbcA" target="_blank"> <IoLogoYoutube style={{border: "none", padding: "6px", background: "red"}} className='contact-us'/> </a></li>
                                    </ul>
                                </div>
                                <div className='col-sm-3'>
                                    <ul className='footer-important-links'>
                                        <li><a rel="noreferrer" href="https://twitter.com/mission_shakti?lang=en#:~:text=Mission%20Shakti%20(%40mission_shakti)%20%2F%20X" target="_blank"> <FaTwitter style={{border: "none", padding: "6px", background: "#00c2ff"}} className='contact-us'/></a></li>
                                    </ul>
                                </div>
                            </div>
                            <div style={{color: "#ffffff", marginTop: 10}}><span>Website visit count:</span> <div><IoEye style={{fontSize: "20px",marginRight: "6px"}}/>{visitorCount}</div></div>
                        </div>
                    </div>
                </div>
                <div className='copyright-cls'>Copyright @Department of Mission Shakti, Government of Odisha, All Rights Reserved</div>
            </div>
            <ActionModal 
            show={modal}
            close={Toggle}
            handleModalSubmit={handleModalSubmit}
            title={modalTitle}
            action={modalAction}
            isFooterHide={true}
            size={"lg"} >
                {modalTitle==='Feedback' && <FeedbackPage feedBackForm={feedFormData} onFormSubmit={handleFormSubmit} action={modalAction}  />}
                {modalTitle==='Website Policy' && <WebsitePolicyPage />}
                {modalTitle==='FAQ' && <FaqPage />}
            </ActionModal>
        </>
    )
}

export default PublicFooter