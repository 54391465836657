import React, { useContext, useState } from "react";
import "./Login.css";
import { useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Button, Row, Col } from "react-bootstrap";
// import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CustomCaptcha from "../common/CustomCaptcha";
import { userPost } from "../../services/http.service"; // Import the API service

import PublicDepartments from "./PublicDepartments";
import MegaMenu from "./MegaMenu";
import PublicHeader from "./PublicHeader_old";
import PublicFooter from "./PublicFooter";
import PublicLogoHeader from "./PublicLogoHeader";
// import PublicBanner from "./PublicBanner";
import { useTranslation } from 'react-i18next';
import { ApplicationContext } from "../ApplicationContext";
// import { useLanguageContext } from '../../LanguageContext'; 

import LogoOdisha from "../../assets/images/OdishaLogo2.png"
import LScooter from "../../assets/images/scooterImage2.jpg"
import ActionModal from "../common/ActionModal";


const Login = () => {
  const { t } = useTranslation();
  const context=useContext(ApplicationContext)
  // const { changeLanguage } = useLanguageContext();
  let navigate = useNavigate();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  // const [captchaResponse, setCaptchaResponse] = useState("");
  const [isAgree, setIsAgree] = useState(false); 
  const [modal, setModal] = useState(false); 
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    defaultValues: {
      captchaResponse: "",
      password: "",
      username: ""
    }
  });

  const onSubmit = async (data: any) => {
    if (!isCaptchaValid) {
      toast.error('Please complete the CAPTCHA.', {
        position: "top-right"
      });
      return;
    }
    const payload = {
      username: data.username,
      password: data.password,
      // captchaResponse: captchaResponse
    };

    try {
      const response = await userPost('/_user/_login', payload); // Update the URL to match your API endpoint
      console.log(response); //
      if (response) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.user.roles[0].roleName);
        localStorage.setItem('UUID', response.data.user.uuid);
        localStorage.setItem('UDAI', response.data.user.aadharNumber);
        localStorage.setItem('UserDetails',  JSON.stringify(response.data.user))
        context.updateUser(response.data.user)
        // console.log('role', response.data.user.roles[0].roleName);
        // localStorage.setItem('token', response.data.token); 
        // console.log('Token updated',response.data.token);
        // Assuming your API returns a token
        toast.success('Login successful!', {
          position: "top-right"
        });
        setTimeout(() => {
          const role = response.data.user.roles[0].roleName;
          // switch (role) {
          //   case 'CSS':
          //     navigate('/mission-shakti/home');
          //     break;
          //   case 'BPC':
          //     navigate('/mission-shakti/block-official/home');
          //     break;
          //   case 'BDO':
          //     navigate('/mission-shakti/bdo/home');
          //     break;
          //   case 'CDPO':
          //     navigate('/mission-shakti/cdpo/home');
          //     break;
          //   case 'BRANCH_BANK':
          //     navigate('/mission-shakti/loan-bank/home');
          //     // console.log('Role' ===role)
          //     break;
          //   case 'CONTROLLING_BANK':
          //     navigate('/mission-shakti/head-bank/home');
          //     break;
          //   case 'ms Department':
          //     navigate('/mission-shakti/ms-department/home');
          //     break;
          //   default:
          //     navigate('/mission-shakti/login');
          //     break;
          // }
          navigate('/mission-shakti/home');
        }, 1000);
      } else {
        toast.error('Login failed. Please check your credentials.', {
          position: "top-right"
        });
      }
    } catch (error:any) {
      console.log("error===",error)
      let errMsg:any = error?.response?.data ? error?.response?.data : 'An error occurred. Please try again later.'
      toast.error(errMsg, {
        position: "top-right"
      });
      console.error("Login error:", error);
    }
  };
  const Toggle = () => setModal(!modal);
  const handleModalSubmit = () => {
    setModal(false); 
  };

  return (
    <>
      <div className="login justify-content-center align-items-center">
        <div className="card mt-3 mb-3" style={{borderRadius: "20px", boxShadow: "0px 4px 7px 0px #00000040"}}>
          <div className="card-body row">
            <div className="col-sm-6" style={{background: "#FF6402", paddingLeft:0, paddingRight:0, borderRadius: "20px",boxShadow: "0px 4px 7px 0px #00000040"}}>
              <div className="reg-left-panel">
                  <div><img src={LogoOdisha} alt="Odisha Logo" style={{width: "30%"}} /></div>
                  <div className="reg-text">Mission Shakti</div>
                  <div className="reg-text">Scooter Yojana</div>
                  <div className="font-s-18">Government of Odisha</div>
                  <div>
                      <img src={LScooter} alt="Scooter" className="log-scooter-image"/>
                  </div>
              </div>
            </div>
            <div className="col-sm-6">
              <h2 className="text-center mb-4 mt-2">{t('login.Sign In')}</h2>
              <form onSubmit={handleSubmit(onSubmit)}  style={{padding: "0px 20px 0px 10px", marginTop: "10px"}}>
                <InputText type="text" name="username" label={t('login.Username')}  placeholder={t('login.UserName Place')} register={{
                  ...register("username", {
                    required: t('login.NameRequired')
                  })
                }} errors={errors}
                />
                <InputText type="password" name="password"  label={t('login.Password')} placeholder={t('login.Password Place')} register={{
                  ...register("password", {
                    required: t('login.PasswordRequired'),
                    minLength: {
                      value: 5,
                      message: "Password should be at-least 6 characters."
                    },
                  })
                }} errors={errors} />

                <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
                {/* <Row id="btn" className="mb-3">
                  <Col className="text-left">
                    <Button className="button-auto-width" variant="secondary" onClick={() => navigate('/mission-shakti')}>{t('login.Cancel')}</Button>
                  </Col>
                  <Col className="text-right">
                    <Button className="button-auto-width" type="submit" variant="primary">{t('login.Submit')}</Button>
                  </Col>
                </Row> */}
                <div className="form-check mt-3">
                  <input 
                    type="checkbox" 
                    className="form-check-input" 
                    id="terms" 
                    checked={isAgree} 
                    onChange={() => setIsAgree(!isAgree)} 
                  />
                  <label className="form-check-label" htmlFor="terms">
    <span style={{ color: "black" }}>I agree to the </span>
    <span 
      style={{ color: "#FF6402", cursor: 'pointer' }} 
      onClick={Toggle} 
    >
      {t('Terms and Conditions')}
    </span>
  </label>
                </div>
                <button 
                  className="generic-btn-submit mt-3" 
                  type="submit" 
                  disabled={!isAgree} 
                >
                  {t('login.Submit')}
                </button>
                <button className="generic-btn-cancel" onClick={() => navigate('/mission-shakti/')}>{t('login.Cancel')}</button>
                                
                <Row className="mt-3">
                  <Col className="text-center f-14">
                    <Link to="/mission-shakti/forgot-password">{t('login.Forgot Password?')}</Link>
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col className="text-center f-14">
                    <div >{t('login.newUser')}
                    <Link to="/mission-shakti/register">{t('login.registerHere')}</Link>
                    </div>
                  </Col>
                </Row>
              </form>
            </div>
            
          </div>
        </div>
      </div>
      <ToastContainer />
      {modal && (
        <ActionModal
          show={modal}
          close={Toggle}
          handleModalSubmit={handleModalSubmit}
          title="Terms and Conditions"
          isFooterHide={true}
          size={"lg"}
        >
          <p>This website is designed, developed, and maintained by the Department of Mission Shakti, Government of Odisha. Though all efforts have been made to ensure the accuracy and currency of the content on this website, the same should not be construed as a statement of law or used for any legal purposes. In case of any ambiguity or doubts, users are advised to verify/check with the Ministry and/or other source(s) and to obtain appropriate professional advice.</p>
          <p>Under no circumstances will this Ministry be liable for any expense, loss, or damage, including, without limitation, indirect or consequential loss or damage, or any expense, loss, or damage whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this website. These terms and conditions shall be governed by and construed in accordance with Indian laws. Any dispute arising under these terms and conditions shall be subject to the jurisdiction of the courts of India.</p>
          <p>The information posted on this website could include hypertext links or pointers to information created and maintained by non-Government/private organizations. The Department of Mission Shakti, Government of Odisha, is providing these links and pointers solely for your information and convenience. When you select a link to an outside website, you are leaving the Department of Mission Shakti, Government of Odisha website and are subject to the privacy and security policies of the owners/sponsors of the outside website.</p>
          <p>Department of Mission Shakti, Government of Odisha, does not guarantee the availability of such linked pages at all times. The Department of Mission Shakti, Government of Odisha, cannot authorize the use of copyrighted materials contained in linked websites. Users are advised to request such authorization from the owner of the linked website.</p>
          <p>Department of Mission Shakti, Government of Odisha, does not guarantee the linked websites comply with Indian Government Web Guidelines.</p>
        </ActionModal>
      )}
    </>
  );
};

export default Login;
