import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const MissionVissionPage = () => {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> Mission and vision </h2>
                    <p>
                        <div className='ft-b'>Mission Statement-</div>
                    “Help construct a society which is self-reliant, conscious of socioeconomic issues, where there is a spirit of cooperation, where women are appropriately skilled to undertake their choice of activities without hindrances or dependence, where there is leadership development while maintaining gender equity & above all each having a respect for the values of others and each striving for the good of the greater society.”
                    </p>
                    <div>
                        <p>
                            <div className='ft-b'>Vision Statement-</div>
                            These activities aim to build a robust framework for women’s socio-economic development, ensuring they have the skills, resources, and confidence to actively participate in the state’s growth.</p>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default MissionVissionPage;
