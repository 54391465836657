import { size } from "lodash";
import React, { useState, ReactNode } from "react";
import { IoArrowDownCircle, IoArrowUpCircle } from "react-icons/io5";
interface CollapsibleProps {
  children: ReactNode;
  title: string;
  openStat? :boolean
}

const Collapsible: React.FC<CollapsibleProps> = ({ children, title, openStat }) => {
  const [isOpen, setIsOpen] = useState<boolean>(openStat || false);

  const toggleOpen = (e:any) => {
    e.preventDefault();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div
      className={`collapseContainer ${isOpen ? 'open' : 'closed'}`}
    >
      <div className="collapseDiv" onClick={toggleOpen}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="open-collapse"
            style={{
              backgroundColor: isOpen ? "#FF6A00" : "#d3d3d3",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
          </div>
          <span className="title">{title}</span>
        </div>

        <button
          style={{ border: "none", fontSize:'1.5em', backgroundColor: "transparent" }}
          aria-expanded={isOpen}
        >
          {isOpen ? <IoArrowUpCircle/>: <IoArrowDownCircle/>}
        </button>
      </div>

      {isOpen && <div className="content">{children}</div>}
    </div>
  );
};

export default Collapsible;
