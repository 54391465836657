import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const Overview = () => {
    const navigate = useNavigate();
    const keyActivities: any = ["Self-Help Group (SHG) Formation and Strengthening: Over 6 lakh SHGs involving more than 70 lakh women, enabling collective savings and financial empowerment.",
   
        "Skill Development: Offering tailored training programs to boost women’s employability in diverse sectors like agriculture, textiles, and handicrafts.",
        
        "Access to Financial Resources: Facilitating microfinance and credit linkages through Mission Shakti Loans, which provide low-interest loans for small businesses and initiatives like the Scooter Yojana, supporting mobility and independence.",
        
        "Entrepreneurship and Livelihood Support: Enabling women to establish micro-enterprises by providing financial assistance, market linkages, and business development services.",
        
        "Leadership and Decision-Making Empowerment: Conducting training programs to promote women’s participation in community governance, local decision-making, and public service.",
        
        "Digital Literacy and Financial Inclusion: Enhancing women’s access to digital banking, digital payments, and online financial services, promoting their involvement in the formal financial sector."]
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> Overview of the Department: </h2>
                    <p>
                    The Department of Mission Shakti, Government of Odisha, was established with the primary goal of empowering women through the promotion of Self-Help Groups (SHGs). It focuses on socio-economic upliftment by providing women with opportunities for skill development, financial inclusion, and entrepreneurship. The department leads various initiatives aimed at fostering gender equality, increasing women’s participation in decision-making, and enhancing livelihoods. Through Mission Shakti, the government has created a robust platform for over 70 lakh women in Odisha, contributing significantly to the state's development.
                    </p>
                    <div>
                        <p style={{fontWeight: 600}}>The Department of Mission Shakti is driving numerous initiatives for women's empowerment in Odisha, focusing on economic independence and leadership. Key activities include:</p>
                        <ul>
                            {keyActivities && keyActivities.map((e:any)=>(<li style={{listStyle: "auto", padding: "5px 0px 5px 0px"}}>
                                    {e}
                            </li>))}
                        </ul>
                        <p>These activities aim to build a robust framework for women’s socio-economic development, ensuring they have the skills, resources, and confidence to actively participate in the state’s growth.</p>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default Overview;
