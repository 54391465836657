import React from 'react';
import { Container, Navbar } from 'react-bootstrap';
import BannerScooter from '../../assets/images/slider-1-1.jpg'
import BannerScooter1 from '../../assets/images/slider-2.jpg'
import BannerScooter2 from '../../assets/images/slider-3.jpg'
import BannerScooter3 from '../../assets/images/Mission-Shakti-Scooter-Yojana1.png'
import { Carousel } from "react-bootstrap";
import BannerBike from "../../assets/images/Banner-Illustration-Bike.png";
import TitleDecore from "../../assets/images/Title-Decore.png";
import ButtonIconScooter from "../../assets/images/Button-ic1.png"
import ApplyLoanIcon from "../../assets/images/applyloan.png"
import BlankInage from "../../assets/images/blankImage.png"
import ScooterYojana2 from "../../assets/images/scootrYojana2.jpeg";
import Image32 from "../../assets/images/image_32.png";
import Image33 from "../../assets/images/image_33.png";
import Image34 from "../../assets/images/image_34.png";
import Image35 from "../../assets/images/image_35.png";
import ProfileImage from "../../assets/images/profile.png";
const Feedback = () => {
    const feedbackList:any = [
        {
            id: 1,
            text: "Feeling empowered and Independent: “Thanks to the Mission Shakti Scooter Yojana, I feel more empowered and independent. Now, I can easily travel for work and personal needs without relying on anyone.”",
            name: "Bimla Devi",
            place: "Raipur, Odisha",
            profileImg: ProfileImage
        },
        {
            id: 2,
            text: "A New Chapter of Mobility: “The scooter has truly transformed my daily life. I can now balance my responsibilities more efficiently, and it's made a huge difference in my business. I am extremely happy with the support provided by Mission Shakti.”",
            name: "Rama Das",
            place: "Angul, Odisha",
            profileImg: ProfileImage
        },
        // {
        //     id: 3,
        //     text: "“Use justify-content utilities on flexbox containers to change the alignment of flex items on the main axis (the x-axis to start, y-axis if flex-direction: column). Choose from start (browser default), end, center, between, or around.”",
        //     name: "Sabitri Devi",
        //     place: "Cuttack, Odisha",
        //     profileImg: ""
        // }
    ]
    const getShortName = (name:any)=> {
        return name ? name.split(" ").map((e:any)=>e.charAt(0)) : ""
    }
    return (
        <div style={{ background: "white" }} className="">
            <div className='container' style={{paddingTop: "40px"}}>
            <div style={{fontWeight: 600, fontSize: "18px", color: "#5E6282", lineHeight: "27px"}}>HAPPY MOMENTS</div>
                <div className='row'>
                    <div className='col-sm-6'>
                        <div className='feedback-txt'>
                            <div>What Women say</div>
                            <div>about Mission Shakti.</div>
                        </div>
                    </div>
                    <div className='col-sm-6 feedback-carousel-main'>
                        <Carousel slide={false} controls={false} className='feedback-carousel'>
                            {feedbackList && feedbackList.map((feedback:any)=>
                                <Carousel.Item style={{ height: '245px' }}>
                                    <div>
                                        <p className='feedback-txt1'>{feedback?.text}</p>
                                        <div style={{paddingTop: 30}} className='d-flex flex-row justify-content-start'>
                                            <div>
                                                {feedback.profileImg ? <img src={feedback.profileImg} alt="Profile Pic" className='feedback-profilepic' /> : <div className='feedback-profilepic' style={{padding: 14, fontWeight: 700}}>{getShortName(feedback.name)}</div>}
                                            </div>
                                            <div>
                                                <span className='feedback-name'>{feedback?.name}</span>
                                                <div className='feedback-address'>
                                                    {feedback?.place}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </Carousel.Item>
                            )}
                        </Carousel>
                    </div>
                    
                </div>
            </div>
            
        </div>
    );
};

export default Feedback;
