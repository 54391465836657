import React, { useState, useEffect } from "react";
import { Button, Row, Col, Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import InputText from "../../../common/InputText";
import InputMultiSelect from "../../../common/InputMultiSelect";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { postData } from "../../../../services/http.service";
import { useTranslation } from "react-i18next";
import { FaEdit } from "react-icons/fa";
import Collapsible from "../../../common/Collapsible";
import { FaCircleInfo } from "react-icons/fa6";
import ActionModal from "../../../common/ActionModal";
import {
  editableUsers,
  extractValuesfromObject,
} from "../../../../utils/constants";
import { vehicleAuditInformation } from "../PreviewConstants";
import PreviewCard from "../../../common/PreviewCard";
import { userRoleName } from "../../../../utils/constants";

const VehicleInfo = ({
  formData,
  setFormData,
  nextStep,
  prevStep,
  userRole,
}) => {
  const applicationStatus =
    formData?.applicationBasicDetails?.applicationStatus;
  const [isEditable, setIsEditable] = useState(false);
  let editable =
    isEditable ||
    (editableUsers?.includes(userRole) &&
      (applicationStatus === "Saved as Draft" ||
        applicationStatus === undefined));

  const { t } = useTranslation();

  const [dataChange, setDataChange] = useState(false);
  const initialFormData = {
    manufacturer: "",
    vehicleModel: "",
    classOfVehicle: "",
    yearManufacture: "",
    priceOfVehicle: "",
    insurancePrice: "",
    registrationCost: "",
    lessMarginMoney: "",
    lessDealerDiscount: "",
    accessoriesCost: "",
    amountOfLoanRequired: "",
    nameAndAddressOfDealer: "",
    tinOfShowroom: "",
    quotationNumber: "",
    quotationDate: "",
    proposedRepaymentPeriod: "",
    proposedMonthlyInstallment: "",
    modeOfRepayment: "",
  };

  const userInfo = JSON.parse(localStorage?.getItem("UserDetails"));
  const [manfacturers, setManfacturers] = useState([]);
  const [vehicleData, setVehicleData] = useState(
    formData?.vehicleProposalDetails || initialFormData
  );

  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: vehicleData,
  });
  useEffect(() => {
    if (vehicleData) {
      reset(vehicleData);
    }
  }, [vehicleData, reset]);
  useEffect(() => {
    if (
      JSON.stringify(vehicleData) ===
      JSON.stringify(formData?.vehicleProposalDetails)
    ) {
      setDataChange(false);
    } else {
      setDataChange(true);
    }
  }, [vehicleData, formData?.vehicleProposalDetails]);
  const mode_pay = [
    { value: "advance_cheque", label: "Advance Cheque" },
    { value: "standing_instruction", label: "Standing Instruction" },
    { value: "ect", label: "ETC" },
  ];

  const payment_tenature = [
    { value: "12", label: 12 },
    { value: "24", label: 24 },
    { value: "36", label: 36 },
    { value: "48", label: 48 },
  ];

  const handleMulti = (selectedOption, name) => {
    const label = selectedOption.label;
    setVehicleData((prevstate) => ({
      ...prevstate,
      [name]: label,
    }));
  };

  const handleModeTypeChange = (selectedOption) => {
    const modeType = selectedOption.label;
    setVehicleData((prevstate) => ({
      ...prevstate,
      modeOfRepayment: modeType,
    }));
  };

  useEffect(() => {
    const dataFetch = async () => {
      // const vehicleDetails = await fetchManfacturers();
      try {
        const vehicleDetails = await postData(
          process.env.REACT_APP_COMMONDROPDOWN_SERVICE
        );
        if (vehicleDetails?.data?.vehicleManufacturer) {
          setManfacturers(vehicleDetails?.data?.vehicleManufacturer);
        }
      } catch (error) {
        toast.error("Something went Wronng..", {
          position: "top-right",
        });
      }
    };
    dataFetch();
    if (formData?.vehicleProposalDetailsAudit?.length > 0) {
      setVehicleProposalDetailsAudit(formData?.vehicleProposalDetailsAudit);
    }
  }, []);
  const onSubmit = async (data) => {
    // setFormData((prevstate) => ({
    //   ...prevstate,
    //   vehicleProposalDetails: { ...vehicleData },
    // }));
    const transformedData = {
      userInfo: userInfo,
      uuid: formData.uuid,
      applicationNumber: formData.applicationNumber,
      applicationBasicDetails: { ...formData.applicationBasicDetails },
      addressDetails: { ...formData.addressDetails },
      membershipQualifications: { ...formData.membershipQualifications },
      bankIncomeDetails: {
        ...formData.bankIncomeDetails,
      },
      vehicleProposalDetails: {
        ...vehicleData,
        isUpdate: userRole === "BPC" && isEditable,
        auditDetails: {
          createdBy: userInfo.uuid,
          lastModifiedBy: userInfo.uuid,
          createdTime: new Date().getTime(),
          lastModifiedTime: new Date().getTime(),
        },
      },
    };

    try {
      const response = await postData(
        process.env.REACT_APP_CREATE_APPLICATION_SERVICE,
        transformedData
      );
      if (
        response.data?.status === "Successful" &&
        response.data?.applicationDetails
      ) {
        setFormData((prevstate) => ({
          ...prevstate,
          uuid: response.data.uuid,
          applicationNumber: response.data.applicationNumber,
          vehicleProposalDetails:
            response.data.applicationDetails.vehicleProposalDetails[0],
        }));
        toast.success("Vehicle Details Saved Successfully", {
          position: "top-right",
          autoClose: 3000,
        });
        setTimeout(() => {
          nextStep();
        }, 2000);
      } else {
        toast.error("Failed to save data", {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error("Failed to save data", {
        position: "top-right",
      });
    }
  };

  const handleChange = (event) => {
    setVehicleData((prevstate) => ({
      ...prevstate,
      [event.target.name]:
        event.target.type === "number"
          ? Number(event.target.value)
          : event.target.value,
    }));
  };
  function timestampToDateFormat(timestamp) {
    if (timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  }

  const handleEditClick = () => {
    setIsEditable(!isEditable);
  };


  const [modal, setModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [modalTitle, setModalTitle] = useState(
    "Vehicle Information Change(s) - Audit"
  );
  const [vehicleProposalDetailsAudit, setVehicleProposalDetailsAudit] =
    useState([]);

  const handleModalSubmit = (e) => {};
  const Toggle = () => {
    setModal(!modal);
  };

  return (
    <div className="PersonalInfo">
      <ToastContainer />
      {/* Card containing the form fields */}

      <div className="card mb-4 tiles_Size">
        <div className="card-body">
          <Collapsible title="Vehicle Details">
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "right",
                bottom: "1rem",
              }}
            >
              {vehicleProposalDetailsAudit?.length > 0 && (
                <button className="btn-icons" onClick={Toggle}>
                  <FaCircleInfo
                    size={20}
                    color={modal ? "green" : "rgb(38 159 217)"}
                  />
                </button>
              )}
              &nbsp; &nbsp;
              {userRole === "BPC" && (
                <button className="btn-icons" onClick={handleEditClick}>
                  <FaEdit size={20} color={isEditable ? "green" : "black"} />
                </button>
              )}
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={4}>
                  <InputMultiSelect
                    key={"manufacturer"}
                    isMulti={false}
                    name="manufacturer"
                    label={t("VehicleDetails.Manufacturer")}
                    placeholder={t("VehicleDetails.Manufacturer Placeholder")}
                    register={control}
                    options={manfacturers.map((m) => {
                      return { value: m.name, label: m.name };
                    })}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) => {
                      handleMulti(selectedOption, "manufacturer");
                    }}
                    value={
                      vehicleData.manufacturer
                        ? {
                            value: vehicleData.manufacturer,
                            label: vehicleData.manufacturer,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    key={"vehicleModel"}
                    type="text"
                    name="vehicleModel"
                    label={t("VehicleDetails.Vehicle Model")}
                    placeholder={t("VehicleDetails.Vehicle Placeholder")}
                    register={{
                      ...register("vehicleModel", {
                        required: "Please enter your Vehicle Model",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    value={vehicleData.vehicleModel}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    key={"classOfVehicle"}
                    name="classOfVehicle"
                    label={t("VehicleDetails.Type/Class")}
                    placeholder={t("VehicleDetails.Type/Class Placeholder")}
                    register={{
                      ...register("classOfVehicle", {
                        required: "Please enter your Type/Class of Two Wheeler",
                      }),
                    }}
                    onChange={handleChange}
                    errors={errors}
                    value={vehicleData.classOfVehicle}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"yearManufacture"}
                    name="yearManufacture"
                    label={t("VehicleDetails.Manufacture Year")}
                    placeholder={t("VehicleDetails.MY Placeholder")}
                    register={{
                      ...register("yearManufacture", {
                        required: "Please enter your Year of manufacture",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    value={vehicleData.yearManufacture}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"priceOfVehicle"}
                    name="priceOfVehicle"
                    label={t("VehicleDetails.Vehicle Price")}
                    placeholder={t("VehicleDetails.Price Placeholder")}
                    register={{
                      ...register("priceOfVehicle", {
                        required:
                          "Please enter your Price of the Vehicle(Ex show price/Quotation)(in Rs)",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    value={vehicleData.priceOfVehicle}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"insuranceCost"}
                    name="insuranceCost"
                    label={t("VehicleDetails.Insurance Cost")}
                    placeholder={t("VehicleDetails.Insurance Placeholder")}
                    register={{
                      ...register("insuranceCost", {
                        required: "Please enter your Add Insurance Cost(in Rs)",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    value={vehicleData.insuranceCost}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"registrationCost"}
                    name="registrationCost"
                    label={t("VehicleDetails.Registration Cost")}
                    placeholder={t("VehicleDetails.Registration Placeholder")}
                    register={{
                      ...register("registrationCost", {
                        required:
                          "Please enter your Add Registration Cost(in Rs)",
                      }),
                    }}
                    onChange={handleChange}
                    errors={errors}
                    value={vehicleData.registrationCost}
                    disabled={!editable}
                    required={true}
                  />
                </Col>

                <Col md={4}>
                  <InputText
                    type="number"
                    key={"lessMarginMoney"}
                    name="lessMarginMoney"
                    label={t("VehicleDetails.Less Margin")}
                    placeholder={t("VehicleDetails.LMM Placeholder")}
                    register={{
                      ...register("lessMarginMoney", {
                        required:
                          "Please enter Less Margin Money (If Any)(in Rs)",
                      }),
                    }}
                    onChange={handleChange}
                    value={vehicleData.lessMarginMoney}
                    errors={errors}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"lessDealerDiscount"}
                    name="lessDealerDiscount"
                    label={t("VehicleDetails.Less Dealer")}
                    placeholder={t("VehicleDetails.Less Dealer Placeholder")}
                    register={{
                      ...register("lessDealerDiscount", {
                        required:
                          "Please enter your Less Dealer/Manufacturer Discount (If Any) (in Rs)",
                      }),
                    }}
                    onChange={handleChange}
                    value={vehicleData.lessDealerDiscount}
                    errors={errors}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"accessoriesCost"}
                    name="accessoriesCost"
                    label={t("VehicleDetails.Accessories Cost")}
                    placeholder={t(
                      "VehicleDetails.Accessories Cost Placeholder"
                    )}
                    register={{
                      ...register("accessoriesCost", {
                        required:
                          "Please enter your Add Accessories Cost(in Rs)",
                      }),
                    }}
                    onChange={handleChange}
                    value={vehicleData.accessoriesCost}
                    errors={errors}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="number"
                    key={"amountOfLoanRequired"}
                    name="amountOfLoanRequired"
                    label={t("VehicleDetails.Loan Amount")}
                    placeholder={t("VehicleDetails.Loan Amount Placeholder")}
                    register={{
                      ...register("amountOfLoanRequired", {
                        required:
                          "Please enter your Amount of Loan Required(in Rs)",
                      }),
                    }}
                    onChange={handleChange}
                    errors={errors}
                    value={vehicleData.amountOfLoanRequired}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    key={"nameAndAddressOfDealer"}
                    name="nameAndAddressOfDealer"
                    label={t("VehicleDetails.Name&Address")}
                    placeholder={t("VehicleDetails.Name&Address Placeholder")}
                    register={{
                      ...register("nameAndAddressOfDealer", {
                        required:
                          "Please enter your Name & Address of the Showroom/Dealer/Seller",
                      }),
                    }}
                    errors={errors}
                    onChange={handleChange}
                    value={vehicleData.nameAndAddressOfDealer}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputText
                    type="text"
                    key={"tinOfShowroom"}
                    name="tinOfShowroom"
                    label={t("VehicleDetails.TIN")}
                    placeholder={t("VehicleDetails.TIN Placeholder")}
                    register={{
                      ...register("tinOfShowroom", {
                        required:
                          "Please enter your TIN of Showroom/Dealer/Seller",
                      }),
                    }}
                    onChange={handleChange}
                    value={vehicleData.tinOfShowroom}
                    errors={errors}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    key={"quotationNumber"}
                    name="quotationNumber"
                    label={t("VehicleDetails.Quotation no")}
                    placeholder={t("VehicleDetails.Quotation Placeholder")}
                    register={{
                      ...register("quotationNumber", {
                        required: "Please enter your Quotation no & Date",
                      }),
                    }}
                    value={vehicleData.quotationNumber}
                    onChange={handleChange}
                    errors={errors}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="date"
                    key={"quotationDate"}
                    name="quotationDate"
                    label={t("VehicleDetails.Quotation Date")}
                    placeholder={t("VehicleDetails.Quotation Date Placeholder")}
                    register={{
                      ...register("quotationDate", {
                        required: "Please select the Quotation date",
                      }),
                    }}
                    errors={errors}
                    value={timestampToDateFormat(vehicleData.quotationDate)}
                    onChange={handleChange}
                    disabled={!editable}
                    required={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <InputMultiSelect
                    isMulti={false}
                    key={"proposedRepaymentPeriod"}
                    name="proposedRepaymentPeriod"
                    label={t("VehicleDetails.Proposed Repayment")}
                    placeholder={t(
                      "VehicleDetails.Proposed Repayment Placeholder"
                    )}
                    register={control}
                    options={payment_tenature}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={(selectedOption) => {
                      handleMulti(selectedOption, "proposedRepaymentPeriod");
                    }}
                    value={
                      vehicleData.proposedRepaymentPeriod
                        ? {
                            value: vehicleData.proposedRepaymentPeriod,
                            label: vehicleData.proposedRepaymentPeriod,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputText
                    type="text"
                    key={"proposedMonthlyInstallment"}
                    name="proposedMonthlyInstallment"
                    label={t("VehicleDetails.Monthly Installment")}
                    placeholder={t(
                      "VehicleDetails.Monthly Installment Placeholder"
                    )}
                    register={{
                      ...register("proposedMonthlyInstallment", {
                        required:
                          "Please enter your Proposed Equated Monthly Installment(in Rs)",
                      }),
                    }}
                    onChange={handleChange}
                    errors={errors}
                    value={vehicleData.proposedMonthlyInstallment}
                    disabled={!editable}
                  />
                </Col>
                <Col md={4}>
                  <InputMultiSelect
                    isMulti={false}
                    key={"modeOfRepayment"}
                    name="modeOfRepayment"
                    label={t("VehicleDetails.Repayment Mode")}
                    placeholder={t("VehicleDetails.Repayment Mode Placeholder")}
                    register={control}
                    options={mode_pay}
                    rules={{
                      required: true,
                      message: "This field is required",
                    }}
                    errors={errors}
                    onChange={handleModeTypeChange}
                    value={
                      vehicleData.modeOfRepayment
                        ? {
                            value: vehicleData.modeOfRepayment,
                            label: vehicleData.modeOfRepayment,
                          }
                        : null
                    }
                    disabled={!editable}
                  />
                </Col>
              </Row>
            </form>
          </Collapsible>
        </div>
      </div>

      {/* Card containing the buttons */}
      {/* <div className="card mb-4 shadow">
        <div className="card-body">
          <Row className="mt-2 twobtn mb-3">
            <Col className="text-left">
              <Button variant="secondary" onClick={prevStep}>
                {t("Previous")}
              </Button>
            </Col>
            <Col className="text-right">
              <Button
                variant="success"
                style={{
                  width:
                    userRole === "CSS" || (userRole === "BPC" && isEditable)
                      ? "8rem"
                      : "fit-content",
                }}
                onClick={
                  userRole === "CSS" || (userRole === "BPC" && isEditable)
                    ? handleSubmit(onSubmit)
                    : () => {
                        nextStep();
                      }
                }
              >
                {userRole === "CSS" || (userRole === "BPC" && isEditable)
                  ? t("Save & Next")
                  : t("Next")}
              </Button>
            </Col>
          </Row>
        </div>
      </div> */}
      <div className="application-btn">
        <div className="d-flex justify-content-between">
          <Button
            variant="secondary"
            onClick={() => prevStep()}
            className="cancelBtn"
          >
            {t("Previous")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={
              editable
                ? dataChange
                  ? handleSubmit(onSubmit)
                  : () => {
                      nextStep();
                    }
                : () => {
                    nextStep();
                  }
            }
            className="primaryBtn"
            style={{ width: "max-content" }}
          >
            {editable
              ? dataChange
                ? t("Save & Continue")
                : t("Next")
              : t("Next")}
          </Button>
        </div>
      </div>

      {modal && (
        <ActionModal
          show={modal}
          close={Toggle}
          handleModalSubmit={handleModalSubmit}
          title={modalTitle}
          action={modalAction}
          isFooterHide={true}
          size={"lg"}
        >
          <div className="card-body">
            {vehicleProposalDetailsAudit?.map((vhData, index) => {
              return (
                <>
                  <Collapsible
                    key={index}
                    openStat={vehicleProposalDetailsAudit?.length === 1}
                    title={"Version : " + vhData?.version}
                  >
                    <Container className="g-10">
                      <Row>
                        {extractValuesfromObject({ ...vhData }, [
                          ...vehicleAuditInformation,
                        ]).map((data) => (
                          <Col
                            key={data?.key}
                            className="col-12 col-md-4 col-lg-4 mb-4"
                          >
                            <PreviewCard
                              title={data?.title}
                              value={data?.value}
                            />
                          </Col>
                        ))}
                      </Row>

                      {vhData?.lastModifiedDetails && (
                        <Row>
                          <Col className="col-12 col-md-4 col-lg-4 mb-4">
                            <PreviewCard
                              title="Last Modified By - Role"
                              value={
                                vhData?.lastModifiedDetails?.role[0]
                                  ?.roleName || "--"
                              }
                            />
                          </Col>

                          <Col className="col-12 col-md-4 col-lg-4 mb-4">
                            <PreviewCard
                              title="Last Modified By - Name"
                              value={
                                vhData?.lastModifiedDetails?.userName || "--"
                              }
                            />
                          </Col>

                          <Col className="col-12 col-md-4 col-lg-4 mb-4">
                            <PreviewCard
                              title="Last Modified Date"
                              value={
                                vhData?.lastModifiedDetails?.lastModifiedDate ||
                                "--"
                              }
                            />
                          </Col>
                        </Row>
                      )}
                    </Container>
                  </Collapsible>
                </>
              );
            })}
          </div>
        </ActionModal>
      )}
    </div>
  );
};

export default VehicleInfo;
