import React from "react";
import "./PreviewCard.css";

interface PreviewCardProps {
  title: string;
  value: string;
}

const PreviewCard: React.FC<PreviewCardProps> = ({ title, value }) => {
  console.log(title, value, "check");
  return (
    <div className="previewContainer">
      <span className="title">{title}</span>
      <span className="value">{value ? value : "N/A"}</span>
    </div>
  );
};

export default PreviewCard;
