import { useState, useEffect, useRef } from "react";
import style from "./ActionTable.module.css";
import _ from "lodash";
import GenericExportDropdown from "./export/GenericExport";
import editIcon from "../../assets/icons/edit.svg";
import viewIcon from "../../assets/icons/view.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import upload from "../../assets/icons/upload.svg";
import download from "../../assets/icons/download.svg";
import { useTranslation } from "react-i18next";
import { FaCodePullRequest } from "react-icons/fa6";

const ActionTable = (props: any) => {
  const { t } = useTranslation();
  const [searchedData, setSearchedData] = useState(props?.rowData || []);
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState<any[]>([]);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<string>("");
  const hideColumns = props?.hideColumns || [];
  useEffect(() => {
    props?.rowData && setSearchedData(props?.rowData);
    setCurrentPage(1);
    const selectedRows = props?.rowData
      .filter((item: any) => item.isChecked)
      .map((item: any) => item.id);
    setSelected(selectedRows);
  }, [props?.rowData]);

  const [itemsPerPage, setItemPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchedData?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(props?.rowData?.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleOnSearch = (e: any) => {
    if (e.target.value) {
      const searchValue = e.target.value.toLowerCase();

      const filterObject = (obj: any): boolean => {
        return Object.values(obj).some((value) => {
          if (typeof value === "object" && value !== null) {
            return filterObject(value);
          }
          return value?.toString().toLowerCase().includes(searchValue);
        });
      };

      const filtered = props?.rowData?.filter((item: any) =>
        filterObject(item)
      );
      setSearchedData(filtered);
      setCurrentPage(1);
    } else setSearchedData(props?.rowData);
  };

  const sortByColumn = (column: string) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const showSortedColumnIcon = (columnName: string) => {
    return sortedColumn === columnName ? (
      sortOrder === "asc" ? (
        <i className="fa fa-long-arrow-up table-arrow" aria-hidden="true" />
      ) : (
        <i className="fa fa-long-arrow-down table-arrow" aria-hidden="true"></i>
      )
    ) : (
      <></>
    );
  };

  useEffect(() => {
    if (sortOrder && sortedColumn) {
      const sortedData = [...searchedData]?.sort((a, b) => {
        const columnValueA = _.get(a, sortedColumn);
        const columnValueB = _.get(b, sortedColumn);

        if (sortOrder === "asc") {
          return typeof columnValueA === "string"
            ? columnValueA?.localeCompare(columnValueB)
            : columnValueA - columnValueB;
        } else {
          return typeof columnValueA === "string"
            ? columnValueB?.localeCompare(columnValueA)
            : columnValueB - columnValueA;
        }
      });
      setSearchedData(sortedData);
    }
  }, [sortOrder, sortedColumn]);

  const handleAdd = () => {};
  const handleDeleteRow = (rowData: any) => {
    props?.onDeleteRow(rowData);
  };
  const handleView = (rowData: any) => {
    props?.onViewRow(rowData);
  };
  const handleDownload = (rowData: any) => {
    props?.download(rowData);
  };

  const onCheckColumn = (event: any, data: any) => {
    if (props?.checkType === "multiCheck") {
      if (event.target.checked) {
        const newSelecteds = currentItems?.map((n: any, index: number) => n); // replaced rowData to currentItems to select only the current page items
        setSelected(newSelecteds);
        props?.onCheckHandler(newSelecteds);
        return;
      }
      setSelected([]);
      props?.onCheckHandler([]);
    } else {
      setSelected([]);
      props?.onCheckHandler([]);
    }
  };

  const onRowCheck = (event: any, id: any, row: any) => {
    if (props?.checkType === "multiCheck") {
      const selectedIndex: any = selected.map((e) => e.id).indexOf(id);
      let newSelected: any = [];
      const NOCHECK_ITEM = -1;
      if (selectedIndex === NOCHECK_ITEM) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      props?.onCheckHandler(newSelected);
    } else {
      const selectedIndex: any = selected.map((e) => e.id).indexOf(id);
      let newSelected: any = [];
      const NOCHECK_ITEM = -1;
      if (selectedIndex === NOCHECK_ITEM) {
        newSelected = newSelected.concat(selected, row);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      props?.onCheckHandler(newSelected);
    }
  };

  const isSelected = (id: any) => {
    const pos: any = selected.map((e) => e.id).indexOf(id);
    return pos !== -1;
  };

  const uncheckLast = () => {
    if (props?.checkType === "singleCheck") {
      let ns: any = selected.slice(1);
      setSelected(ns);
    }
  };

  const handlePullBack = (rowData: any) => {
    props?.pullBack(rowData);
  };
  useEffect(() => {
    let x: any = document.getElementById("table-column-check");
    const checkedItems = selected.every((id) =>
      currentItems.some((obj: any) => obj.id === id)
    );

    if (props?.checkType) {
      if (
        selected.length > 0 &&
        selected.length < props?.rowData?.length &&
        selected.length === currentItems.length &&
        checkedItems
      ) {
        x.indeterminate = true;
      } else {
        x.indeterminate = false;
      }
      if (selected.length > 1 && props?.checkType === "singleCheck") {
        uncheckLast();
      }
      // props?.onCheckHandler(selected);
    }
  }, [selected, currentItems]);

  const onRowClick = (event: any, rowData: any) => {
    props?.additionalMethod(rowData);
  };
  const onChangeRowsPerPage = (item: any) => {
    setItemPerPage(item);
  };
  const PaginationOptions = () => {
    const [isShow, setIsShow] = useState(false);
    const exportRef = useRef<any>();

    useEffect(() => {
      const handleOutsideClick = (e: any) => {
        if (exportRef.current && !exportRef.current.contains(e.target)) {
          setIsShow(false);
        }
      };
      document.addEventListener("click", handleOutsideClick);

      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);

    return (
      <div
        id="export_button"
        ref={exportRef}
        className={`d-inline-flex flex-row align-items-center justify-content-end stickyCard`}
      >
        <div className="ps-3">Rows per pages</div>
        <div className="dropdown">
          <button
            className="items-button dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="font-14">{itemsPerPage}</span>
          </button>
          <ul
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton1"
            style={{ padding: 0, minWidth: "56px" }}
          >
            {[10, 25, 50, 100].map((item, i) => (
              <li
                key={i}
                onClick={() => onChangeRowsPerPage(item)}
                className="drop-down-panel-li"
              >
                <a className="dropdown-item" href="jascript:void(0)">
                  {item}
                </a>
              </li>
            ))}
          </ul>
        </div>
        {/* <button
          onClick={() => setIsShow(!isShow)}
          type={"button"}
          className={`Button_small me-3 pagination-count-button`}
          style={{ minWidth: "50px" }}
        >
          <span className="font-14">{itemsPerPage}</span>
        </button>
        {isShow && (
          <div
            className="dropdown-content userprofile-content"
            style={{
              top: "-160px",
              width: 50,
              borderRadius: 4,
              cursor: "pointer",
            }}
          >
            {[10, 25, 50, 100].map((item) => (
              <div
                onClick={() => onChangeRowsPerPage(item)}
                style={{ borderBottom: "1px solid #ccc", borderRadius: 0 }}
              >
                {item}
              </div>
            ))}
          </div>
        )} */}
      </div>
    );
  };

  return (
    <>
      <div className="mb-5 px-0 mx-0 generic-action-table pt-3">
        <div
          className={`row ${style.application_search} ${
            props?.hideSearchBar
              ? "justify-content-end mb-2"
              : "justify-content-between"
          }`}
        >
          <div className="col-sm-6 my-2 fw-600">
            {props?.tableHeader ? props?.tableHeader : t("Table.MyWorkList")}
          </div>
          {!props.hideSearchBar && (
            <input
              type="search"
              onChange={handleOnSearch}
              placeholder={t("Table.Search")}
              className={`my-2 p-2 col-sm-4 ${
                props?.hideSearchBar && "d-none"
              }`}
            />
          )}
          {props.rowData?.length > 0 && !props.hideExportBtn && (
            <GenericExportDropdown
              columns={props.columns}
              rowData={props.rowData}
            />
          )}
          {props?.showCustomBtn && (
            <div
              id="table_custom_button_div"
              className={`col-sm-2 my-2`}
              style={{ width: "12%" }}
            >
              <button
                onClick={props?.handleBtnClick}
                type={"button"}
                disabled={props?.isCustomBtnDisabled}
                className={`background-saffron Export_button`}
                style={{ width: "100%" }}
                id="table_custom_button"
              >
                <span className="font-14">{props?.btnName}</span>
                {/* <span className='Export_button_saperator' /> <i className='fa fa fa-angle-down'></i> */}
                {/* <img alt='' className='Export_button_downarrow' src={DownArrow} /> */}
              </button>
            </div>
          )}
        </div>
        <div style={{ overflow: "auto" }}>
          <table
            className={`${style.table}`}
            style={{ width: `${props?.tableWidth}` }}
          >
            <tbody>
              <tr className={` ${style.table_header}`}>
                {props?.columns?.map((data: any, colIndx: any) => {
                  const checkedItems = selected.every((item) =>
                    currentItems.some((obj: any) => obj.id === item.id)
                  );
                  return data.fieldName === "checkbox" &&
                    props?.checkType === "multiCheck" ? (
                    <th className={`px-0 py-2 ps-3 pe-3 ${data?.clsName}`}>
                      <input
                        type="checkbox"
                        id="table-column-check"
                        ref={(input) => {
                          if (input) {
                            if (selected.length === currentItems.length)
                              input.indeterminate = true;
                            else {
                              input.indeterminate = false;
                            }
                          }
                        }}
                        checked={
                          props?.rowData?.length > 0 &&
                          selected.length === currentItems.length &&
                          checkedItems
                        }
                        name="column-check"
                        onClick={(e) => onCheckColumn(e, data)}
                      />
                    </th>
                  ) : (
                    !hideColumns.includes(data.fieldName) && (
                      <th
                        className={`px-0 py-2 ps-3 pe-3 ${data?.clsName}`}
                        style={{
                          paddingLeft: `${
                            props?.checkType === "multiCheck" && colIndx === 1
                              ? "6px"
                              : colIndx === 0
                              ? "6px"
                              : ""
                          }`,
                        }}
                        onClick={() => sortByColumn(data.fieldName)}
                      >
                        {data.titleCode
                          ? data.titleCode
                          : t(`Table.${data.colName}`)}{" "}
                        {showSortedColumnIcon(data.fieldName)}
                      </th>
                    )
                  );
                })}
              </tr>
            </tbody>
            {searchedData.length !== 0 && (
              <>
                <tbody className={`bg-white ${style.table_datarow}`}>
                  {/* <div className={`bg-white ${style.table_datarow}`}> */}
                  {currentItems?.map((row: any, index: number) => {
                    const rowId = row.id;
                    const isItemSelected = isSelected(rowId);

                    return (
                      <>
                        <tr className={`   ${style.table_data}`}>
                          {props?.columns.map((o: any, colIndex: any) =>
                            o?.isClickable ? (
                              <td
                                className={`px-0 py-2 ps-3 pe-3 ${o.clsName} ${
                                  style.actionTable_table_data
                                } ${
                                  colIndex === props?.columns.length - 1
                                    ? style.last_column
                                    : (o.fieldName !== "checkbox" &&
                                        colIndex === 0) ||
                                      (o.fieldName !== "checkbox" &&
                                        colIndex === 1)
                                    ? style.first_column
                                    : ""
                                }`}
                                style={{
                                  color: "#3370D4",
                                  cursor: "pointer",
                                  background: "#ececf9",
                                  fontWeight: `${
                                    row.isRead === false ? "bolder" : "normal"
                                  }`,
                                }}
                                onClick={(e) => onRowClick(e, row)}
                              >
                                <span>{_.get(row, o.fieldName)}</span>
                              </td>
                            ) : o.fieldName === "checkbox" ? (
                              <td
                                className={`px-0 py-2 ps-3 pe-3 ${o.clsName} ${style.actionTable_table_data}`}
                              >
                                <input
                                  type="checkbox"
                                  key={Math.random()}
                                  checked={isItemSelected}
                                  name="row-check"
                                  onChange={(event) =>
                                    onRowCheck(event, rowId, row)
                                  }
                                />
                                <span className="bigcheck-target"></span>
                              </td>
                            ) : !hideColumns.includes(o.fieldName) &&
                              o.fieldName !== "action" ? (
                              <td
                                className={`px-0 py-2 ps-3 pe-3 ${o.clsName} ${
                                  style.actionTable_table_data
                                } ${
                                  colIndex === props?.columns.length - 1
                                    ? style.last_column
                                    : (o.fieldName !== "checkbox" &&
                                        colIndex === 1) ||
                                      (o.fieldName !== "checkbox" &&
                                        colIndex === 0)
                                    ? style.first_column
                                    : ""
                                }`}
                                style={{
                                  background: "#ececf9",
                                  fontWeight: `${
                                    row.isRead === false ? "bolder" : "normal"
                                  }`,
                                }}
                              >
                                {o?.colName === "Status" && (
                                  <span
                                    className={`${
                                      style.actionTable_table_data
                                    } status-column ${_.get(
                                      row,
                                      o.fieldName
                                    )}-status`}
                                  >
                                    {_.get(row, o.fieldName)}{" "}
                                    {o.fieldName2
                                      ? _.get(row, o.fieldName2)
                                      : ""}
                                  </span>
                                )}
                                {o?.colName !== "Status" && (
                                  <span>
                                    {_.get(row, o.fieldName)}{" "}
                                    {o.fieldName2
                                      ? _.get(row, o.fieldName2)
                                      : ""}
                                  </span>
                                )}
                              </td>
                            ) : (
                              !hideColumns.includes(o.fieldName) &&
                              o.fieldName === "action" && (
                                <td
                                  className={`px-0 py-2 ps-3 pe-3 ${o.clsName} ${style.actionTable_table_data}`}
                                  style={{
                                    background: "#ececf9",
                                    // display: "flex",
                                    // justifyContent: "center",
                                  }}
                                >
                                  {o.actions.includes("add") && (
                                    <button
                                      className={`${style.arrow}`}
                                      onClick={handleAdd}
                                      type="button"
                                    >
                                      <i
                                        className="fa fa-plus"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  )}
                                  {o.actions.includes("edit") && (
                                    <button
                                      className={`${style.arrow}`}
                                      onClick={() => props.handleEditRow(row)}
                                      type="button"
                                    >
                                      <img
                                        src={editIcon}
                                        alt="edit"
                                        className="table-action-icon"
                                      />
                                    </button>
                                  )}
                                  {o.actions.includes("delete") && (
                                    <button
                                      className={`${style.arrow}`}
                                      onClick={() => handleDeleteRow(row)}
                                      type="button"
                                    >
                                      <img
                                        src={deleteIcon}
                                        alt="delete"
                                        className="table-action-icon"
                                      />
                                    </button>
                                  )}
                                  {o.actions.includes("view") && (
                                    <button
                                      className={`${style.arrow}`}
                                      onClick={() => handleView(row)}
                                      type="button"
                                    >
                                      <img
                                        src={viewIcon}
                                        alt="view"
                                        className="table-action-icon"
                                      />
                                    </button>
                                  )}

                                  {o.actions.includes("download") &&
                                    _.get(row, "applicationStatus") ===
                                      "APPROVED_BY_BDO" && (
                                      <button
                                        className={`${style.arrow}`}
                                        onClick={() => handleDownload(row)}
                                        type="button"
                                      >
                                        <img
                                          src={download}
                                          alt="download"
                                          className="table-action-icon"
                                        />
                                      </button>
                                    )}

                                  {o.actions.includes("upload") &&
                                    _.get(row, "applicationStatus") ===
                                      "APPROVED_BY_BDO" && (
                                      <button
                                        className={`${style.arrow}`}
                                        onClick={() => handleView(row)}
                                        type="button"
                                      >
                                        <img
                                          src={upload}
                                          alt="upload"
                                          className="table-action-icon"
                                        />
                                      </button>
                                    )}
                                  {o.actions.includes("pullback") &&
                                    _.get(row, "applicationStatus") ===
                                      "APPROVED_BY_BDO" && (
                                      <button
                                        className={`${style.arrow}`}
                                        onClick={() => handlePullBack(row)}
                                        type="button"
                                      >
                                        <FaCodePullRequest
                                          style={{ color: "black" }}
                                        />
                                      </button>
                                    )}
                                </td>
                              )
                            )
                          )}
                        </tr>
                      </>
                    );
                  })}
                  {/* </div> */}
                </tbody>
              </>
            )}
          </table>
        </div>

        {searchedData.length === 0 && (
          <div className="text-center fw-bold mt-3">
            <h5>{t("Table.Sorry")}</h5>
          </div>
        )}
        <div
          className={`col-sm-12  py-3 position-relative  d-flex justify-content-between align-items-center  ${style.table_footer}`}
        >
          <div className="ps-2">
            Entries found : {props?.rowData?.length || searchedData.length}
          </div>
          <div
            className="d-flex align-items-center"
            style={{ marginRight: 30 }}
          >
            <div>
              <PaginationOptions />
            </div>
            <div style={{ flex: "initial" }}>
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className={`${style.arrow}`}
              >
                <i
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                  style={{ color: "black" }}
                ></i>
              </button>

              <span className={` mx-1 ${style.pagenumber}`}>{currentPage}</span>

              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className={`${style.arrow}`}
              >
                <i
                  className="fa fa-arrow-right"
                  aria-hidden="true"
                  style={{ color: "black" }}
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionTable;
