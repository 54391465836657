import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  scales,
  RadialLinearScale,
} from "chart.js";
import { useEffect, useState } from "react";
import { Bar, Doughnut, PolarArea } from "react-chartjs-2";
import InputMultiSelect from "../../common/InputMultiSelect";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Button } from "react-bootstrap";
import { MONTH_LIST } from "../../../utils/constants";
import { postData } from "../../../services/http.service";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import { Colors } from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  RadialLinearScale
);

export const barChartOptions: any = {
  indexAxis: "x",
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Status of fund disbursement by Mission Shakti Department",
    },
  },
};

export const doughnutChartOptions: any = {
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Status of Scooter Loans",
    },
  },

  type: "doughnut",
  data: [],
};

const polarChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    title: {
      display: true,
      text: "Performance assessment of Banks  ",
    },
  },
};
const response: any = {
  districtsWiseCounts: [
    {
      districtName: "ANGUL",
      approvedCount: 10,
    },
    {
      districtName: "Angul",
      approvedCount: 20,
    },
    {
      districtName: "BALESHWAR",
      approvedCount: 0,
    },
    {
      districtName: "BARGARH",
      approvedCount: 30,
    },
    {
      districtName: "BHADRAK",
      approvedCount: 40,
    },
    {
      districtName: "BOLANGIR",
      approvedCount: 22,
    },
    {
      districtName: "BOUDH",
      approvedCount: 456,
    },
    {
      districtName: "Balasore",
      approvedCount: 234,
    },
    {
      districtName: "CUTTACK",
      approvedCount: 544,
    },
    {
      districtName: "DEOGARH",
      approvedCount: 230,
    },
    {
      districtName: "DHENKANAL",
      approvedCount: 500,
    },
    {
      districtName: "GAJAPATI",
      approvedCount: 200,
    },
    {
      districtName: "GANJAM",
      approvedCount: 2000,
    },
    {
      districtName: "JAGATSINGHAPUR",
      approvedCount: 1000,
    },
    {
      districtName: "JAJPUR",
      approvedCount: 6000,
    },
    {
      districtName: "JHARSUGUDA",
      approvedCount: 3780,
    },
    {
      districtName: "Jajpur",
      approvedCount: 133,
    },
    {
      districtName: "KALAHANDI",
      approvedCount: 4890,
    },
    {
      districtName: "KANDHAMAL",
      approvedCount: 388,
    },
    {
      districtName: "KENDRAPARA",
      approvedCount: 877,
    },
    {
      districtName: "KENDUJHAR",
      approvedCount: 8765,
    },
    {
      districtName: "KHORDHA",
      approvedCount: 4565,
    },
    {
      districtName: "KORAPUT",
      approvedCount: 377,
    },
    {
      districtName: "MALKANGIRI",
      approvedCount: 599,
    },
    {
      districtName: "MAYURBHANJ",
      approvedCount: 4987,
    },
    {
      districtName: "NABARANGAPUR",
      approvedCount: 1809,
    },
    {
      districtName: "NAYAGARH",
      approvedCount: 5898,
    },
    {
      districtName: "NUAPADA",
      approvedCount: 989,
    },
    {
      districtName: "PURI",
      approvedCount: 1000,
    },
    {
      districtName: "RAYAGADA",
      approvedCount: 3000,
    },
    {
      districtName: "SAMBALPUR",
      approvedCount: 5900,
    },
    {
      districtName: "SUBARNAPUR",
      approvedCount: 40,
    },
    {
      districtName: "SUNDARGARH",
      approvedCount: 20,
    },
  ],
  totalApprovedCounts: 1,
  yearWiseCounts: [
    {
      month: "January",
      count: 50,
    },
    {
      month: "February",
      count: 100,
    },
    {
      month: "March",
      count: 30,
    },
    {
      month: "April",
      count: 45,
    },
    {
      month: "May",
      count: 86,
    },
    {
      month: "June",
      count: 23,
    },
    {
      month: "July",
      count: 66,
    },
    {
      month: "August",
      count: 10,
    },
    {
      month: "September",
      count: 85,
    },
    {
      month: "October",
      count: 95,
    },
    {
      month: "November",
      count: 100,
    },
    {
      month: "December",
      count: 30,
    },
  ],

  statusofScooterLoans: [
    {
      titleName: "No. of Loans applied",
      count: 50,
    },
    {
      titleName: "No. of Loans in process",
      count: 30,
    },
    {
      titleName: "No. of Loans approved",
      count: 10,
    },
    {
      titleName: "No. of Loans Rejected",
      count: 10,
    },
  ],

  statusofFundDisBurse: [
    {
      titleName: "Total fund Approved",
      count: 150,
    },
    {
      titleName: "Total Fund Processed",
      count: 30,
    },
    {
      titleName: "Total Fund Released / Disbursed",
      count: 50,
    },
    {
      titleName: "Total Fund Utilized",
      count: 20,
    },
    {
      titleName: "Total Fund Balance with Mission Shakti Department",
      count: 50,
    },
  ],
};

const defFilterFormData: any = {
  district: "",
  year: "",
  month: "",
};

const AdminAnalyticsView = () => {
  const [filterFormData, setfilterFormData] = useState(defFilterFormData);

  const [barChartlabels, setBarChartLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);

  const [doughNutChartlabels, setDoughNutChartLabels] = useState([]);
  const [doughNutChartData, setDoughNutChartData] = useState([]);
  const [finYearList, setFinYearList] = useState<any[]>([]);

  const masterBaseURL: any = process.env.REACT_APP_MASTER_SERVICE;
  const masterServiceEndPoint: any = URLS_ENDPOINT?.MASTER_SERVICES;
  const monthList: any[] = [...MONTH_LIST];
  const [districts, setDistricts] = useState([]);
  const [yearList, setYearList] = useState([]);
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  useEffect(() => {
    if (response) {
      if (response?.districtsWiseCounts?.length > 0) {
        let barLabels: any = [];
        let barDataList: any = [];
        response?.districtsWiseCounts?.forEach((e: any) => {
          barLabels.push(e.districtName);
          barDataList.push(e.approvedCount);
        });
        setBarChartLabels(barLabels);
        setBarChartData(barDataList);
      }

      if (response?.statusofScooterLoans?.length > 0) {
        let labels: any = [];
        let dataList: any = [];
        response?.statusofScooterLoans?.forEach((e: any) => {
          labels.push(e.titleName);
          dataList.push(e.count);
        });
        setDoughNutChartLabels(labels);
        setDoughNutChartData(dataList);
      }

      if (response?.statusofFundDisBurse?.length > 0) {
        let labels: any = [];
        let dataList: any = [];
        response?.statusofFundDisBurse?.forEach((e: any) => {
          labels.push(e.titleName);
          dataList.push(e.count);
        });
        setBarChartLabels(labels);
        setBarChartData(dataList);
      }
    }

    let today: any = new Date();
    let currentYear: any = today.getFullYear();
    let years: any = [
      {
        label: currentYear,
        value: currentYear,
      },
    ];
    for (let i = 1; i < 11; i++) {
      let obj: any = {
        label: currentYear - i,
        value: currentYear - i,
      };
      years.push(obj);
    }
    setYearList(years);

    async function loadDistricts() {
      const response: any = postData(
        masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
      );

      response
        .then((res: any) => {
          const { state, districts } = res?.data?.addressDetails;
          setDistricts(districts);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }

    async function getFinYearList() {
      try {
        let finURL  = process.env.REACT_APP_MASTER_SERVICE+URLS_ENDPOINT.MASTER_SERVICES.FINYEAR_DROPDOWN;
        const response = await postData(finURL);
        if (response?.data?.financialYears?.length > 0) {
          let tempList: any[] = [];
          for (const key in response?.data?.financialYears) {
            tempList.push({
              value: response?.data?.financialYears[key].uuid,
              label: response?.data?.financialYears[key].financialYear,
            });
          }
          setFinYearList(tempList);
        }
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    }
    
    getFinYearList();
    loadDistricts();
  }, []);

  const barchartData = {
    labels: monthList?.map((mon) => mon.label),
    datasets: [
      {
        label: "Total fund approved for Mission Shakti Scooter Yojana",
        data: [65, 59, 80, 81, 56, 55, 40, 57, 78,55,55, 43],
        borderColor: "#9BD0F5",
        backgroundColor: "#9BD0F5",
      },
      {
        label: "Total fund processed for Mission Shakti Scooter Yojana",
        data: [28, 48, 40, 19, 86, 27, 90, 57, 78,55,55, 43],
        borderColor: "#FFB1C1",
        backgroundColor: "#FFB1C1",
      },
      {
        label:
          "Total fund released/disbursed for Mission Shakti Scooter Yojana",
        data: [18, 24, 36, 48, 60, 72, 84, 57, 78,55,55, 43],
      },

      {
        label: "Total fund utilized for Mission Shakti Scooter Yojana",
        data: [18, 24, 36, 48, 60, 72, 84, 57, 78,55,55, 43],
      },

      {
        label:
          "Total fund balance with Mission Shakti Department for Mission Shakti Scooter Yojana",
        data: [18, 24, 36, 48, 60, 72, 84, 57, 78,55,55, 43],
      },
    ],
  };

  const BarChartFinalData = {
    labels: barchartData.labels,
    datasets: barchartData.datasets.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      backgroundColor:
        dataset?.backgroundColor ||
        `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(
          Math.random() * 255
        )}, ${Math.floor(Math.random() * 255)}, 0.5)`,
    })),
  };

  // const BarChartFinalData = {
  //   labels: barChartData?.length > 0 ? [...barChartlabels] : [],
  //   datasets: [
  //     {
  //       label : "asdasd",
  //       data: barChartData?.length > 0 ? [...barChartData] : [],
  //       backgroundColor: ["#FF6384", "#36A2EB", "#82e0aa", "#ec7063"],
  //       hoverBackgroundColor: ["#FF6384", "#36A2EB", "#82e0aa", "#ec7063"],
  //     },
  //   ],
  // }

  doughnutChartOptions.data =
    doughNutChartData.length > 0 ? [...doughNutChartData] : [];

  const DNutChartFinalData = {
    labels: doughNutChartlabels?.length > 0 ? [...doughNutChartlabels] : [],
    datasets: [
      {
        data: doughNutChartData?.length > 0 ? [...doughNutChartData] : [],
        backgroundColor: ["#FF6384", "#36A2EB", "#82e0aa", "#ec7063"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#82e0aa", "#ec7063"],
      },
    ],
    text: "Total: " + doughNutChartData?.length,
  };

  const handleOnYearChange = (e: any) => {
    console.log("handleOnYearChange==", e);
    // Need to call api to refresh chart, based on selected year
    setfilterFormData((prevstate: any) => ({
      ...prevstate,
      year: e,
    }));
  };

  const handleOnMonthChange = (e: any) => {
    console.log("handleOnMonthChange==", e);
    setfilterFormData((prevstate: any) => ({
      ...prevstate,
      month: e,
    }));
  };

  const handleOnDistChange = (e: any) => {
    console.log("handleOnDistChange==", e);
    setfilterFormData((prevstate: any) => ({
      ...prevstate,
      district: e,
    }));
  };

  const handleFilterReset = async (e: any) => {
    setfilterFormData(defFilterFormData);
  };

  const onSubmitFilterForm = async (e: any) => {
    try {
    } catch (error) {
      console.error("Error onSubmit Filter Form :", error);
    }
  };
  const polarGraphData = {
    labels: [
      "Loans Processed", // a
      "Loans in Transit", // b
      "Loans Rejected", // c
      "Average Processing Time",
      "Standard Reporting Time",
      "Non-Performing Assets (NPAs)",
      "Loan Defaulters",
    ],
    datasets: [
      {
        label: "Loan Statistics",
        data: [50, 30, 20, 15, 10, 9, 5], // Example values for each parameter
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(255, 206, 86, 0.6)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(255, 206, 86, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <div className="portal-analytic-main">
        {/* <div
          style={{ padding: "0.5rem" }}
          className="analytic-header"
        >
          Analytic View
        </div> */}
        <div>
          <div className=" tab-content" id="myTabContent">
            <Card>
              <form
                style={{ padding: "1rem", margin: 0 }}
                onSubmit={handleSubmit(onSubmitFilterForm)}
              >
                <Row>
                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.district}
                      isMulti={false}
                      name="district"
                      label={t("AddressInfo.District")}
                      placeholder="Select your district"
                      register={control}
                      options={districts?.map((d: any) => {
                        return {
                          value: d.uuid,
                          label: d.districtName,
                        };
                      })}
                      errors={errors}
                      onChange={handleOnDistChange}
                      defaultValue={
                        filterFormData?.district
                          ? {
                              ...filterFormData?.district,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.year}
                      isMulti={false}
                      name="year"
                      label="Financial Year"
                      placeholder="Select Financial Year"
                      register={control}
                      options={finYearList}
                      errors={errors}
                      onChange={handleOnYearChange}
                      defaultValue={
                        filterFormData?.year
                          ? {
                              ...filterFormData?.year,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.month}
                      isMulti={false}
                      name="month"
                      label="Month"
                      placeholder="Select Month"
                      register={control}
                      options={monthList}
                      errors={errors}
                      onChange={handleOnMonthChange}
                      defaultValue={
                        filterFormData?.month
                          ? {
                              ...filterFormData?.month,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3} style={{ textAlign: "center" }}>
                    <Button
                      className="btn background-saffron"
                      type="submit"
                      style={{ marginTop: "1rem", width: "5rem" }}
                    >
                      Filter
                    </Button>

                    <Button
                      className="btn btn-reset"
                      type="reset"
                      onClick={handleFilterReset}
                      style={{ marginTop: "1rem", marginLeft: "1rem" }}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </form>

              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <Row>
                  <Col md={5}>
                    <Doughnut
                      options={doughnutChartOptions}
                      data={DNutChartFinalData}
                      className="protal-chart"
                      width={"500px"}
                      height={"500px"}
                    />
                  </Col>

                  <Col md={7}>
                    <Bar
                      options={barChartOptions}
                      data={BarChartFinalData}
                      className="protal-chart"
                      width={"500px"}
                      height={"500px"}
                    />
                  </Col>
                </Row>
              </div>
              <div>
                <Row>
                <form
                style={{ padding: "1rem", margin: 0 }}
              >
                <Row>
                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.district}
                      isMulti={false}
                      name="district"
                      label={t("AddressInfo.District")}
                      placeholder="Select your district"
                      register={control}
                      options={districts?.map((d: any) => {
                        return {
                          value: d.uuid,
                          label: d.districtName,
                        };
                      })}
                      errors={errors}
                      onChange={handleOnDistChange}
                      defaultValue={
                        filterFormData?.district
                          ? {
                              ...filterFormData?.district,
                            }
                          : null
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.district}
                      isMulti={false}
                      name="district"
                      label={t("AddressInfo.District")}
                      placeholder="Select your district"
                      register={control}
                      options={districts?.map((d: any) => {
                        return {
                          value: d.uuid,
                          label: d.districtName,
                        };
                      })}
                      errors={errors}
                      onChange={handleOnDistChange}
                      defaultValue={
                        filterFormData?.district
                          ? {
                              ...filterFormData?.district,
                            }
                          : null
                      }
                    />
                  </Col>
                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.year}
                      isMulti={false}
                      name="year"
                      label="Financial Year"
                      placeholder="Select Financial Year"
                      register={control}
                      options={finYearList}
                      errors={errors}
                      onChange={handleOnYearChange}
                      defaultValue={
                        filterFormData?.year
                          ? {
                              ...filterFormData?.year,
                            }
                          : null
                      }
                    />
                  </Col>

                  <Col md={3}>
                    <InputMultiSelect
                      key={filterFormData.month}
                      isMulti={false}
                      name="month"
                      label="Month"
                      placeholder="Select Month"
                      register={control}
                      options={monthList}
                      errors={errors}
                      onChange={handleOnMonthChange}
                      defaultValue={
                        filterFormData?.month
                          ? {
                              ...filterFormData?.month,
                            }
                          : null
                      }
                    />
                  </Col>
                </Row>

                <Col md={3} style={{ float: "right", textAlign:'right' }}>
                    <Button
                      className="btn background-saffron"
                      type="submit"
                      style={{ marginTop: "0.5rem", width: "5rem" }}
                    >
                      Filter
                    </Button>

                    <Button
                      className="btn btn-reset"
                      type="reset"
                      style={{ marginTop: "0.5rem", marginLeft: "1rem" }}
                    >
                      Reset
                    </Button>
                  </Col>
              </form>
                </Row>
              </div>
              <Row className="justify-content-center">

                <Col md={6}>
                  <PolarArea
                    data={polarGraphData}
                    options={polarChartOptions}
                  />
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAnalyticsView;
