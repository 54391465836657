import { Button, Modal, Form } from "react-bootstrap";

const ActionModal = (props:any) => {
    const handleButtonSubmit = (e:any) => {
        props?.handleModalSubmit(e,props?.action);
      };
    return (
        <>
            <Modal show={props?.show} onHide={() => props.close(false)} size={props?.size}>
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title className="h5">
                  {props?.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <main className="modal_content">{props?.children}</main>
                
              </Modal.Body>
              {!props?.isFooterHide && <Modal.Footer>
                <Button variant="primary" onClick={handleButtonSubmit} className="primaryBtn" style={{ width: "max-content" }}>
                  Submit
                </Button>
              </Modal.Footer>}
            </Modal>
        </>
    )
}
export default ActionModal;
