import { useTranslation } from 'react-i18next';
import PublicFooter from './PublicFooter';
import PublicHeader from './PublicHeader';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
const ProgremeeOverviewPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1); // Navigate to the previous page
    };
    return (
        <>
            <PublicHeader />
            <div style={{paddingTop: "110px"}}> 
                <div className='container' >
                    <h2><IoArrowBack onClick={handleBackClick}/> {t('Programme Overview')} </h2>
                    <p>
                        The Mission Shakti Scooter Yojana is a visionary initiative by the Government of Odisha aimed at empowering women, particularly those involved in Self-Help Groups (SHGs) under Mission Shakti. In recognition of the Federation Leaders and Community Support Staff (CSS) towards women empowerment, State government had announced “Interest Free Loan for purchase of scooter by Mission Shakti Federation Leaders and Community Support Staff”.
                    </p>
                    <div>
                        <p>
                            Under this scheme, the beneficiaries will receive interest free bank loan up to Rs.1 lakh to purchase scooter (electric/ petrol/hybrid) of their own choice with a maximum repayment period of 4 years (48 months). The beneficiary does not need to pay any margin money or down payment for availing the scooter loan up to Rs. 1 lakh. 
                        </p>
                        <p>
                            It is of paramount importance to submit adequate number of loan applications to bank branches by the eligible beneficiaries in time. In this connection, a common loan application form has been developed in consultation with the banks. The field functionaries of Mission Shakti shall verify the loan documents and the same shall be recommended by Block Development Officer (BDO)/ Child Development Project Officer (CDPO).
                        </p>
                        <p>
                            In view of the above, the field functionaries are impressed upon to act swiftly and ensure that all eligible beneficiaries are covered under this new scheme. 
                        </p>
                    </div>
                </div>
            </div>
            <PublicFooter />
        </>
        
    );
};

export default ProgremeeOverviewPage;
