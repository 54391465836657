// import axios from "axios";
import axiosInstance from "./axios/config";
import axios from "axios";
// const gidcBaseURL: any = process.env.REACT_APP_GIDC_SERVICE;
const userBaseURL: any = process.env.REACT_APP_USER_SERVICE;
// const dropdownAddressURL: any = process.env.REACT_APP_ADDRESS_DROPDOWN_SERVICE;
// const dropdownBankURL: any = process.env.REACT_APP_BANKS_DROPDOWN_SERVICE;
// const dropdownBankIFSCURL: any = process.env.REACT_APP_BANKSIFSC_SERVICE;
// const utilsPincodeURL: any = process.env.REACT_APP_UTILS_PINCODE_SERVICE;
// const createApplicationURL: any =
//   process.env.REACT_APP_CREATE_APPLICATION_SERVICE;
const updateApplicationURL: any =
  process.env.REACT_APP_UPDATE_APPLICATION_SERVICE;
const OTPURL: any = process.env.REACT_APP_OTP_SERVICE;
const APPLICATIONSURL: any = process.env.REACT_APP_APPLICATIONS_SERVICE;
// const CommonDropdownURL: any = process.env.REACT_APP_COMMONDROPDOWN_SERVICE;
// const workflowApplicationURL: any = process.env.REACT_APP_WORKFLOW_APPLICATION_SERVICE;
// const UpdateProfileURL: any = process.env.REACT_APP_UPDATE_PROFILE_SERVICE;
const FileUploadURL:any=process.env.REACT_APP_FILE_UPLOAD;
// const SearchApplication :any=process.env.REACT_APP_APPLICATION_SEARCH

// export const gidcPost = (url: string, payload: any) => {
//     return axios.post(gidcBaseURL + url, payload)
// }

export const userPost = (url: string, payload: any) => {
  return axiosInstance.post(userBaseURL + url, payload);
};
export const userRegister = (url: string, payload: any) => {
  return axiosInstance.post(userBaseURL + url, payload);
};
export const forgotPassword = (url: string, payload: any) => {
  return axiosInstance.post(OTPURL + url, payload);
};
export const validateOTP = (url: string, payload: any) => {
  return axiosInstance.post(OTPURL + url, payload);
};
export const resetPassword = (url: string, payload: any) => {
  return axiosInstance.post(userBaseURL + url, payload);
};
// export const changePassword = async (payload: any) => {
//   try {
//     const response = await axios.post(`${userBaseURL}/_user/_resetPassword?isProfileUpdate=true`, payload, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
export const changePassword = async (payload: any) => {
  try {
    const response = await axios.post(`${userBaseURL}/_user/_resetPassword?isProfileUpdate=true`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error: any) {
    if (error.response && error.response.data) {
      throw {
        status: error.response.status,
        message: error.response.data,
      };
    } else {
      throw {
        status: 500,
        message: "An unknown error occurred",
      };
    }
  }
};

// export const userLogout = (token: string) => {
//     return axios.post('http://164.52.212.74:8098/mis-service/_auth/_logout', {}, {
//         headers: {
//             Authorization: token
//         }
//     });
// }
// export const userLogout = (token: string) => {
//   return axios.post(
//     `${userBaseURL}/_user/_logout`,
//     {},
//     {
//       headers: {
//         Authorization: token,
//       },
//     }
//   );
// };

// Address-related API calls

// export const fetchDistricts = async () => {
//   try {
//     const response = await axios.post(dropdownAddressURL);
//     return {
//       state: response.data.addressDetails.state,
//       districts: response.data.addressDetails.districts,
//     };
//   } catch (error) {
//     throw error;
//   }
// };
// export const fetchDistricts = async () => {
//   try {
//     const response = await axios.post(dropdownAddressURL);
//     return response.data.addressDetails.districts;
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchBlocks = async (districtId: string) => {
//   try {
//     const response = await axios.post(
//       `${dropdownAddressURL}?districtId=${districtId}`
//     );
//     return response.data.addressDetails.blocks;
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchPanchayats = async (blockId: string) => {
//   try {
//     const response = await axios.post(
//       `${dropdownAddressURL}?blockId=${blockId}`
//     );
//     return response.data.addressDetails.panchayats;
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchVillages = async (panchayatId: string) => {
//   try {
//     const response = await axios.post(
//       `${dropdownAddressURL}?panchayatId=${panchayatId}`
//     );
//     return response.data.addressDetails.villages;
//   } catch (error) {
//     throw error;
//   }
// };

// Bank-related API calls
// export const fetchBankDetails = async (ifsc_code: string) => {
//   try {
//     const response = await axios.post(
//       `${dropdownBankIFSCURL}?ifsc=${ifsc_code}`
//     );
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
// export const fetchBankList = async () => {
//   try {
//     const response = await axios.post(dropdownBankURL);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchPostOfficesByPincode = async (pincode: string) => {
//   try {
//     const response = await axios.post(`${utilsPincodeURL}?pincode=${pincode}`);
//     return response.data.PostOffice;
//   } catch (error) {
//     throw error;
//   }
// };

// Application-related API calls
// export const createApplication = async (payload: any) => {
//   try {
//     const response = await axios.post(createApplicationURL, payload, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const fileUpload = async (payload: any) => {
  try {
    const response = await axios.post(
      FileUploadURL,
      payload,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (err) {
    throw err;
  }
};
export const updateApplication = async (payload: any) => {
  try {
    const response = await axios.post(updateApplicationURL, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getApplications = async (token: string, userInfo: any) => {
  try {
    const response = await axiosInstance.post(
      `${APPLICATIONSURL}/getWorklistApplications`,
      { userInfo },
      // {
      //   headers: {
      //     Authorization: token,
      //     "Content-Type": "application/json",
      //   },
      // }
    );
    return response.data.dashboardApplications;
  } catch (error) {
    throw error;
  }
};

// export const fetchCommonDropdowns = async () => {
//   try {
//     const response = await axios.post(CommonDropdownURL);
//     // return {
//     //   educationalQualification: response.data.educationalQualification,
//     //   individualType: response.data.individualType,
//     // };
//     return response.data;

//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchEducationalQualifications = async () => {
//   try {
//     const response = await axios.post(CommonDropdownURL);
//     return response.data.educationalQualification || [];
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchIndividualTypes = async () => {
//   try {
//     const response = await axios.post(CommonDropdownURL);
//     return response.data.individualType || [];
//   } catch (error) {
//     throw error;
//   }
// };

// export const fetchManfacturers = async () => {
//   try {
//     const response = await axios.post(CommonDropdownURL, {});
//     return response.data.vehicleManufacturer || [];
//   } catch (error) {
//     throw error;
//   }
// };

// export const searchUserByUUID = async (uuid: string) => {
//   try {
//     const response = await axios.post(
//       `${userBaseURL}/_user/_search?uuids=${uuid}`
//     );
//     return response.data.users;
//   } catch (error) {
//     throw error;
//   }
// };

// export const searchApplication = async (payload: any) => {
//   try {
//     const response = await axios.post(
//       SearchApplication,payload);
//     return response.data
//   } catch (err) {
//   }
// };


// export const workflowApplication = async (payload: any) => {
//   try {
//       const response = await axios.post(workflowApplicationURL, payload);
//       return response.data;
//   } catch (error) {
//       throw error;
//   }
// };

// export const editApplication = async (payload : any) => {
//   try {
//       const response = await axios.post(editApplicationURL, payload);
//       return response;
//   } catch (error) {
//       console.error('API Error:', error);
//       throw error;
//   }
// };


// export const UpdateProfile = async (payload: any) => {
//   try {
//     const response = await axios.post(UpdateProfileURL, payload, {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     return response.data;
//   } catch (error: any) {
//     if (error.response && error.response.data) {
//       throw {
//         status: error.response.status,
//         message: error.response.data,
//       };
//     } else {
//       throw {
//         status: 500,
//         message: "An unknown error occurred",
//       };
//     }
//   }

// };


export const getData = async (url: string) => {
  return await axiosInstance.get(url);
};

export const postData = async(url: any, payload?: any, headers?:any) => {
  return await axiosInstance.post(url, payload || {},  headers || {});
};

export const updateData = async(url: string, payload?: any) => {
  return await axiosInstance.put(url, payload || {});
};



