import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  scales
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { Pie } from 'react-chartjs-2';
import InputMultiSelect from '../common/InputMultiSelect';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options:any = {
  indexAxis: 'y',
  responsive: true,
  maintainAspectRatio: false,
  scaleShowValues: true,
  plugins: {
    
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'District-wise scooters rolled out',
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false      
      },
      ticks: {
        maxRotation: 50,
        minRotation: 30,
        padding: 10,
        autoSkip: false,
        fontSize: 10
      }
    },
    y: {
      stacked: true,
      ticks: {
        maxRotation: 50,
        minRotation: 30,
        padding: 10,
        autoSkip: false,
        fontSize: 10
      },
      grid: {
        display: false      
      }
    }
  }
};

const response:any = {
  "districtsWiseCounts": [
      {
          "districtName": "ANGUL",
          "approvedCount": 10
      },
      {
          "districtName": "Angul",
          "approvedCount": 20
      },
      {
          "districtName": "BALESHWAR",
          "approvedCount": 0
      },
      {
          "districtName": "BARGARH",
          "approvedCount": 30
      },
      {
          "districtName": "BHADRAK",
          "approvedCount": 40
      },
      {
          "districtName": "BOLANGIR",
          "approvedCount": 22
      },
      {
          "districtName": "BOUDH",
          "approvedCount": 456
      },
      {
          "districtName": "Balasore",
          "approvedCount": 234
      },
      {
          "districtName": "CUTTACK",
          "approvedCount": 544
      },
      {
          "districtName": "DEOGARH",
          "approvedCount": 230
      },
      {
          "districtName": "DHENKANAL",
          "approvedCount": 500
      },
      {
          "districtName": "GAJAPATI",
          "approvedCount": 200
      },
      {
          "districtName": "GANJAM",
          "approvedCount": 2000
      },
      {
          "districtName": "JAGATSINGHAPUR",
          "approvedCount": 1000
      },
      {
          "districtName": "JAJPUR",
          "approvedCount": 6000
      },
      {
          "districtName": "JHARSUGUDA",
          "approvedCount": 3780
      },
      {
          "districtName": "Jajpur",
          "approvedCount": 133
      },
      {
          "districtName": "KALAHANDI",
          "approvedCount": 4890
      },
      {
          "districtName": "KANDHAMAL",
          "approvedCount": 388
      },
      {
          "districtName": "KENDRAPARA",
          "approvedCount": 877
      },
      {
          "districtName": "KENDUJHAR",
          "approvedCount": 8765
      },
      {
          "districtName": "KHORDHA",
          "approvedCount": 4565
      },
      {
          "districtName": "KORAPUT",
          "approvedCount": 377
      },
      {
          "districtName": "MALKANGIRI",
          "approvedCount": 599
      },
      {
          "districtName": "MAYURBHANJ",
          "approvedCount": 4987
      },
      {
          "districtName": "NABARANGAPUR",
          "approvedCount": 1809
      },
      {
          "districtName": "NAYAGARH",
          "approvedCount": 5898
      },
      {
          "districtName": "NUAPADA",
          "approvedCount": 989
      },
      {
          "districtName": "PURI",
          "approvedCount": 1000
      },
      {
          "districtName": "RAYAGADA",
          "approvedCount": 3000
      },
      {
          "districtName": "SAMBALPUR",
          "approvedCount": 5900
      },
      {
          "districtName": "SUBARNAPUR",
          "approvedCount": 40
      },
      {
          "districtName": "SUNDARGARH",
          "approvedCount": 20
      }
  ],
  "totalApprovedCounts": 1,
  "yearWiseCounts": [
      {
          "month": "January",
          "count": 50
      },
      {
          "month": "February",
          "count": 100
      },
      {
          "month": "March",
          "count": 30
      },
      {
          "month": "April",
          "count": 45
      },
      {
          "month": "May",
          "count": 86
      },
      {
          "month": "June",
          "count": 23
      },
      {
          "month": "July",
          "count": 66
      },
      {
          "month": "August",
          "count": 10
      },
      {
          "month": "September",
          "count": 85
      },
      {
          "month": "October",
          "count": 95
      },
      {
          "month": "November",
          "count": 100
      },
      {
          "month": "December",
          "count": 30
      }
  ]
}


// const labels = ['January', 'February', 'March', 'April', 'May', 'June','July', 'August', 'September', 'October', 'November', 'December'];

const PortalAnalyticView = () => {
  const [labels, setBarChartLabels] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  // const yearList:any = [{value: 2023, label: 2023},{value: 2024, label: 2024}];
  const [yearList, setYearList] = useState([])
  const { t } = useTranslation();
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {

    },
  });

  useEffect(()=>{
    if(response) {
      if(response?.districtsWiseCounts?.length>0) {
        let barLabels:any = [], barDataList:any=[];
        response?.districtsWiseCounts.map((e:any)=>{
          barLabels.push(e.districtName);
          barDataList.push(e.approvedCount)
        });
        setBarChartLabels(barLabels);
        setBarChartData(barDataList);
      }
      if(response?.yearWiseCounts?.length>0) {
        let lineLabels:any = [], lineDataList:any=[];
        response?.yearWiseCounts.map((e:any)=>{
          lineLabels.push(e.month);
          lineDataList.push(e.count)
        });
        setLineChartLabels(lineLabels);
        setLineChartData(lineDataList);
      }
    }
    let today:any = new Date();
    let currentYear:any = today.getFullYear();
    console.log("currentYear===",currentYear);
    let years:any = [{
      label: currentYear,
      value: currentYear
    }];
    for(let i=1; i<11; i++ ) {
        let obj:any = {
          label: currentYear-i,
          value: currentYear-i,
        }
        years.push(obj)
    }
    setYearList(years)
  },[])
  const BarChartData = {
    labels,
    datasets: [{
      axis: 'y',
      label: 'Total Scooters Rolled out',
      data: barChartData,
      fill: true,
      backgroundColor: '#ff6402',
      // backgroundColor: [
      //   'rgba(255, 99, 132, 0.2)',
      //   'rgba(255, 159, 64, 0.2)',
      //   'rgba(255, 205, 86, 0.2)',
      //   'rgba(75, 192, 192, 0.2)',
      //   'rgba(54, 162, 235, 0.2)',
      //   'rgba(153, 102, 255, 0.2)',
      //   'rgba(201, 203, 207, 0.2)'
      // ],
      // borderColor: [
      //   'rgb(255, 99, 132)',
      //   'rgb(255, 159, 64)',
      //   'rgb(255, 205, 86)',
      //   'rgb(75, 192, 192)',
      //   'rgb(54, 162, 235)',
      //   'rgb(153, 102, 255)',
      //   'rgb(201, 203, 207)'
      // ],
      borderWidth: 1
    }]
  };
  const lineChart:any = {
    labels: lineChartLabels,
    datasets: [
      {
        label: 'Month-wise Scooter Rolled Out',
        data: lineChartData,
        fill: true,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      }
    ]
  };
  const handleDateChange = (e:any)=>{
    console.log("handleDateChange==",e)
    // Need to call api to refresh line chart, based on selected year
  }
  return (
    <>
      <div style={{ marginTop: "30px",marginBottom:'20px'}} className="container">
        <div className="portal-analytic-main">
          {/* <div className="analytic-header">Mission Shakti Scooter Yojana</div> */}
          <div>
            {/* <ul className="nav nav-tabs justify-content-center" id="myTab" role="tablist" style={{marginBottom: 60}}>
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">This Week</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Last Week</button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Last Month</button>
              </li>
            </ul> */}
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div className='row'>
                    <div style={{}} className='col-sm-6'>
                      <Bar options={options} data={BarChartData} className='protal-chart' width={"400px"} height={"600px"} />
                    </div>
                    <div style={{height: "400px"}} className='col-sm-6'>
                      <InputMultiSelect
                        key={"year"}
                        isMulti={false}
                        name="year"
                        label={t("year")}
                        register={control}
                        options={yearList}
                        // rules={{
                        //   required: true,
                        //   message: "This field is required",
                        // }}
                        errors={errors}
                        onChange={handleDateChange}
                      />
                      <Line data={lineChart} className='protal-chart' height={"200px"}  options={{ maintainAspectRatio: false }} />
                      <div className='happy-count'>
                        <div id="emoji" className="emoji">NO OF HAPPY WOMEN😊 : {response?.totalApprovedCounts}</div>
                      </div>
                    </div>
                  </div>
                  
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...Profile</div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...contact</div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default PortalAnalyticView;
