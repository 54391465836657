export const USER_REGIS_MASTER = {
  USER_INFO_COLUMNS: [
    // {
    //     colName: "User Id",
    //     fieldName: "id",
    //     clsName: "column-text-start",
    //     isClickable: false,
    //   },
    {
      colName : "Sl#",
      fieldName: "slNo",
      clsName: "column-text-start",
      isClickable: false,
    },
    {
      colName: "User Name",
      fieldName: "userName",
      clsName: "column-text-start",
      isClickable: false,
    },
    {
      colName: "Name as per Aadhar",
      fieldName: "nameAsPerAadhar",
      clsName: "column-text-start",
    },

    {
       colName: "Role",
       fieldName: "roleName",
       clsName: "column-text-start",
     },

    {
     colName: "Contact No.",
        fieldName: "mobileNumber",
      clsName: "column-text-start",
    },
    {
      colName: "Aadhaar No.",
      fieldName: "aadharNumber",
      clsName: "column-text-start",
      isClickable: false,
    },
    {
      colName: "Address",
      fieldName: "address",
      clsName: "column-text-start",
      isClickable: false,
    },
    {
      colName: "Action",
      fieldName: "action",
      actions: "edit",
      clsName: "column-text-start actions-column",
    },
  ],
};
