import React, { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import InputText from "../../common/InputText";
import { t } from "i18next";
import InputMultiSelect from "../../common/InputMultiSelect";
import { postData } from "../../../services/http.service";
import {
  APP_MESSAGES,
  CONSTANT_REGEX_SAMPLE,
  salutations,
  genderTypeList,
  BLOCK_LEVEL_ROLES,
  BANK_LEVEL_ROLES,
} from "../../../utils/constants";
import InputCheck from "../../common/InputCheck";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import Collapsible from "../../common/Collapsible";

interface inputProps {
  userFormData: any;
  onFormSubmit: (params: any) => any;
  action: any;
}

const UserManageForm = ({
  userFormData = {},
  onFormSubmit,
  action,
}: inputProps) => {
  const masterBaseURL: any = process.env.REACT_APP_MASTER_SERVICE;
  const masterServiceEndPoint: any = URLS_ENDPOINT?.MASTER_SERVICES;

  const [formData, setFormData] = useState(userFormData);
  const [districts, setDistricts] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [panchayats, setPanchayats] = useState([]);
  const [projectDetailsList, setProjectDetailsList] = useState<any[]>([]);

  const salutionInfo = [...salutations];
  const genderTypes = [...genderTypeList];
  const [roleList, setRoleList] = useState<any>([]);
  const [bankList, setBankList] = useState<any[]>([]);
  const [ifscList, setifscList] = useState<any[]>([]);


  const [checkOptions, setCheckedOptions]: any = useState([
    {
      label: "Active",
      value: true,
    },
  ]);

  useEffect(() => {
    async function loadDistricts() {
      const response: any = postData(
        masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
      );

      response
        .then((res: any) => {
          const { state, districts } = res?.data?.addressDetails;
          setDistricts(districts);
          loadBlocks(districts);
        })
        .catch((err: any) => {
          toast.error(err?.message, {
            position: "top-right",
          });
        });
    }

    async function loadBlocks(districts: any) {
      if (formData.districtName) {
        const districtId: any = districts.find((d: any) => {
          return d.districtName === formData.districtName;
        });

        if (districtId) {
          try {
            const blockRes: any = await postData(
              `${
                masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
              }?districtId=${districtId.uuid}`
            );
            const blocks = blockRes?.data?.addressDetails?.blocks || [];
            setBlocks(blocks);
            loadPanchayats(blocks, districtId?.uuid);
          } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
          }
        }
      }
    }
    async function loadPanchayats(blocks: any, districtId: any) {
      if (formData.blockName) {
        const blockId: any = blocks.find((d: any) => {
          return d.blockName === formData.blockName;
        });
        if (blockId) {
          try {
            const panchayatRes: any = await postData(
              `${
                masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
              }?blockId=${blockId?.uuid}`
            );
            const panchayats =
              panchayatRes?.data?.addressDetails?.panchayats || [];
            setPanchayats(panchayats);
            loadProjectDetails(panchayats, districtId, blockId?.uuid);
          } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
          }
        }
      }
    }
    async function loadProjectDetails(
      panchayats: any,
      districtId?: any,
      blockId?: any
    ) {
      if (formData.panchayatName) {
        const panchayat: any = panchayats.find((d: any) => {
          return d.panchayatName === formData.panchayatName;
        });
        if (panchayat) {
          try {
            const projectRes: any = await postData(
              masterBaseURL + masterServiceEndPoint?.PROJECT_DROPDOWN,
              {
                districtCode: districtId,
                blockCode: blockId,
                panchayatCode: panchayat?.uuid,
              }
            );
            const projects = projectRes?.data?.icdsProjectDetails || [];
            setProjectDetailsList(projects);

            if(userFormData && userFormData?.projectName){
              setValue("projectName", userFormData?.projectName);
              setValue("projectCode", userFormData?.projectCode);
              setFormData((prevstate: any) => ({
                ...prevstate,
                projectName: userFormData?.projectName,
              }))
              
            }

          } catch (error) {
            toast.error(APP_MESSAGES.API_ERR, {
              position: "top-right",
            });
          }
        }
      }
    }

    async function loadRoleLists() {
      const response: any = postData(
        process.env.REACT_APP_COMMONDROPDOWN_SERVICE
      );

      response
        .then((res: any) => {
          let roles: any[] = res?.data?.roleMaster || [];
          if (roles?.length > 0) {
            roles = roles?.filter((user) => user.roleCode !== "100");
            if(userFormData && userFormData?.rolesCode){
              const selRole: any = roles.find((d: any) => {
                return d.roleCode === userFormData?.rolesCode;
              });
              setValue("rolesCode", selRole?.roleCode);
              setFormData((prevstate: any) => ({
                ...prevstate,
                rolesCode: selRole?.roleName,
              }))
              
            }
          }
          setRoleList(roles);
        })
        .catch((err: any) => {
          toast.error(err?.message, {
            position: "top-right",
          });
        });
    }

    async function loadBanksList() {
      try {
        const response = await postData(
          process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
          {
            bankFilterDetails: {
              bankCode: "",
              branchCode: "",
            },
          }
        );
        const bankList: any[] = response?.data?.masterBankDetails || []; // Ensure banks array is extracted from the response
        if (bankList.length > 0) {
          let tempBankList: any[] = [];
          for (const key in bankList) {
            tempBankList.push({
              value: bankList[key].code,
              label: bankList[key].bankName,
            });
          }
          setBankList(tempBankList);
        }
      } catch (error) {
        console.error("Error fetching bank list:", error);
      }
    }


    if(userFormData){

      const selGen: any = genderTypes?.find((g: any) => {
        return g.value === userFormData?.gender;
      });
      setValue("gender",selGen?.label);
      setFormData((prevstate: any) => ({
        ...prevstate,
        gender: selGen?.label
      }))

      const selSal: any = salutionInfo?.find((d: any) => {
        return d.value === userFormData?.salutation;
      });
      setValue("salutation",selSal?.label);
      setFormData((prevstate: any) => ({
        ...prevstate,
        salutation: selSal?.label
      }))
      
    }


    loadDistricts();
    loadRoleLists();
    loadBanksList();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: formData,
  });
  const handleFormDetails = (event: any) => {};
  const handleDistrictChange = async (e: any) => {
    const districtId = e.value;
    try {
      const blockRes: any = await postData(
        `${
          masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
        }?districtId=${districtId}`
      );
      const blocks = blockRes?.data?.addressDetails?.blocks || [];
      setBlocks(blocks);
      setValue("districtName", e.label);
      setValue("blockName", "");
      setValue("panchayatName", "");
      setValue("projectName", "");
      setValue("projectCode", "");
      setFormData((prevstate: any) => ({
        ...prevstate,
        districtName: e.label,
      }));
      setFormData((prevstate: any) => ({ ...prevstate, blockName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, panchayatName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, projectName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, projectName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, projectCode: "" }));

    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };
  const handleBlockChange = async (e: any) => {
    const blockId = e.value;
    try {
      const panchayatRes: any = await postData(
        `${
          masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
        }?blockId=${blockId}`
      );
      const panchayats = panchayatRes?.data?.addressDetails?.panchayats || [];
      setPanchayats(panchayats);
      setValue("blockName", e.label);
      setValue("panchayatName", "", { shouldDirty: true });
      setValue("projectName", "");
      setValue("projectCode", "");
      setFormData((prevstate: any) => ({ ...prevstate, blockName: e.label }));
      setFormData((prevstate: any) => ({ ...prevstate, panchayatName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, projectName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, projectCode: "" }));


    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };

  const handlePanchayatChange = async (e: any) => {
    const panchayatId = e.value;
    try {
      setValue("panchayatName", e.label);
      setValue("projectName", "");
      setValue("projectCode", "");
      setFormData((prevstate: any) => ({
        ...prevstate,
        panchayatName: e.label,
      }));

      const districtId: any = districts.find((d: any) => {
        return d.districtName === formData?.districtName;
      });

      const blockId: any = blocks.find((d: any) => {
        return d.blockName === formData.blockName;
      });

      const projectRes: any = await postData(
        masterBaseURL + masterServiceEndPoint?.PROJECT_DROPDOWN,
        {
          districtCode: districtId?.uuid,
          blockCode: blockId?.uuid,
          panchayatCode: panchayatId,
        }
      );
      const projects = projectRes?.data?.icdsProjectDetails || [];
      setProjectDetailsList(projects);
      setFormData((prevstate: any) => ({ ...prevstate, projectName: "" }));
      setFormData((prevstate: any) => ({ ...prevstate, projectCode: "" }));

    } catch (error) {
      toast.error(APP_MESSAGES.API_ERR, {
        position: "top-right",
      });
    }
  };

  const handleProjectChange = (selectedOption: any) => {
    setValue("projectName", selectedOption?.label);
    setValue("projectCode", selectedOption?.value);
    setFormData((prevstate: any) => ({
      ...prevstate,
      projectName: selectedOption?.label,
    }));

    setFormData((prevstate: any) => ({
      ...prevstate,
      projectCode: selectedOption?.value,
    }));
  }


  const handleBankSelect = async (selectedOption: any) => {
    setFormData((prevstate: any) => ({
      ...prevstate,
      bankName: selectedOption?.label,
    }));

    setFormData((prevstate: any) => ({
      ...prevstate,
      ifscCode: "",
    }));

    setValue("bankName", selectedOption.label);
    setifscList([]);

    try {
      const response = await postData(
        process.env.REACT_APP_BANKS_DROPDOWN_SERVICE,
        {
          bankFilterDetails: {
            bankCode: selectedOption?.value,
            branchCode: "",
          },
        }
      );
      const branchDetailsList: any[] = response?.data?.branchDetails || []; // Ensure banks array is extracted from the response
      if (branchDetailsList.length > 0) {
        let tempList: any[] = [];
        for (const key in branchDetailsList) {
          tempList.push({
            value: branchDetailsList[key].ifscCode,
            label:
              branchDetailsList[key].ifscCode +
              " (" +
              branchDetailsList[key].branchName +
              ")",
          });
        }
        setifscList(tempList);
      }
    } catch (error) {
      console.error("Error fetching bank list:", error);
    }
  };

  const handleDate = (e: any) => {
    const birthDate = new Date(e?.target?.value);
    const today = new Date();
    const calculatedAge = today.getFullYear() - birthDate.getFullYear();
    setFormData((prevstate: any) => ({
      ...prevstate,
      ageOnDate: calculatedAge,
      dob: e?.target?.value,
    }));
  };

  const onChangeCheckBox = (e: any) => {
    console.log("onChangeCheckBox==", e);
  };

  return (
    <>
      <ToastContainer />
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="card mb-4 tiles_Size">
          <Collapsible
            key={1}
            title="Enterprise User Information"
            openStat={true}
          >
            <Row>
              <Col md={6}>
                <InputMultiSelect
                  key={formData.rolesCode}
                  isMulti={false}
                  name="rolesCode"
                  label={t("PersonalInfo.userType")}
                  placeholder="-- Select user type -- "
                  register={control}
                  options={roleList?.map((r: any) => {
                    return { value: r?.roleCode, label: r?.roleName };
                  })}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={async (e: any) => {
                    setValue("rolesCode", e.label);
                    setFormData((prevstate: any) => ({
                      ...prevstate,
                      rolesCode: e.label,
                    }));
                  }}
                  defaultValue={
                    formData?.rolesCode
                      ? {
                          value: formData.rolesCode,
                          label: formData.rolesCode,
                        }
                      : null
                  }
                />
              </Col>

              <Col md={6}>
                <InputMultiSelect
                  key={formData.salutation}
                  isMulti={false}
                  name="salutation"
                  label={t("PersonalInfo.Salution")}
                  placeholder="-- Select salutation -- "
                  register={control}
                  options={salutionInfo}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue("salutation", e.label);
                    setFormData((prevstate: any) => ({
                      ...prevstate,
                      salutation: e.label,
                    }));
                  }}
                  defaultValue={
                    formData.salutation
                      ? {
                          value: formData.salutation,
                          label: formData.salutation,
                        }
                      : null
                  }
                />
              </Col>
              <Col md={6}>
                <InputText
                  key={formData?.nameAsPerAadhar}
                  type="text"
                  name="nameAsPerAadhar"
                  label={t("Register.AadharName")}
                  placeholder={t("Enter your Name")}
                  register={{
                    ...register("nameAsPerAadhar", {
                      required: "Please enter your Name",
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.NAME,
                        message: "Invalid Name",
                      },
                      maxLength: {
                        value: 100,
                        message: "Name should not more than 100 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.userName}
                  type="text"
                  name="userName"
                  label={t("Register.Uname")}
                  maxlength={30}
                  placeholder={t("Register.Uname Place")}
                  register={{
                    ...register("userName", {
                      required: "Please enter your Name",
                      maxLength: {
                        value: 30,
                        message: "Name should not more than 30 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.firstName}
                  type="text"
                  name="firstName"
                  label={t("PersonalInfo.First Name")}
                  maxlength={100}
                  placeholder={t("PersonalInfo.First Name")}
                  register={{
                    ...register("firstName", {
                      required: "Please enter your First Name",
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.NAME,
                        message: "Invalid Name",
                      },
                      maxLength: {
                        value: 100,
                        message: "Name should not more than 100 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.middleName}
                  type="text"
                  name="middleName"
                  label={t("PersonalInfo.Middle Name")}
                  maxlength={100}
                  placeholder={t("PersonalInfo.Middle Name")}
                  register={{
                    ...register("middleName", {
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.NAME,
                        message: "Invalid Name",
                      },
                      maxLength: {
                        value: 100,
                        message: "Name should not more than 100 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.lastName}
                  type="text"
                  name="lastName"
                  maxlength={100}
                  label={t("PersonalInfo.Last Name")}
                  placeholder={t("PersonalInfo.Last Name")}
                  register={{
                    ...register("lastName", {
                      required: "Please enter your Last Name",
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.NAME,
                        message: "Invalid Name",
                      },
                      maxLength: {
                        value: 100,
                        message: "Name should not more than 100 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.mobileNumber}
                  type="text"
                  name="mobileNumber"
                  maxlength={10}
                  label={t("Mobile No.")}
                  placeholder={t("Enter Mobile No.")}
                  register={{
                    ...register("mobileNumber", {
                      required: "Please enter your  Mobile No.",
                      minLength: {
                        value: 10,
                        message: "Name should not less than 10 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.aadharNumber}
                  type="text"
                  name="aadharNumber"
                  label={t("Aadhaar No.")}
                  placeholder={t("Enter Aadhaar No.")}
                  register={{
                    ...register("aadharNumber", {
                      required: "Please enter your  Aadhaar No.",
                      minLength: {
                        value: 12,
                        message:
                          "Aadhaar Number should not less than 12 digits.",
                      },
                      maxLength: {
                        value: 12,
                        message:
                          "Aadhaar Number should not more than 12 digits.",
                      },
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                        message: "Aadhaar Number should be numeric.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  // value={formData?.aadharNumber}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.alternateMobileNumber}
                  type="text"
                  name="alternateMobileNumber"
                  maxlength={10}
                  label={t("EditProfile.Alternate Number")}
                  placeholder="Enter Alternate Mobile No."
                  register={{
                    ...register("alternateMobileNumber", {
                      minLength: {
                        value: 10,
                        message: "Name should not less than 10 characters.",
                      },
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                />
              </Col>

              <Col md={6}>
                <InputText
                  type="text"
                  key={formData?.emailId}
                  name="emailId"
                  label={t("PersonalInfo.Email")}
                  placeholder="Enter your Email"
                  register={{
                    ...register("emailId", {
                      required: "Please enter your Email",
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.EMAIL,
                        message: "Invalid Email.",
                      },
                    }),
                  }}
                  errors={errors}
                  disabled={false}
                  readOnly={false}
                  onChange={handleFormDetails}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputMultiSelect
                  key={formData.gender}
                  isMulti={false}
                  name="gender"
                  label={t("PersonalInfo.Gender")}
                  placeholder="Select"
                  register={control}
                  options={genderTypes}
                  rules={{
                    required: true,
                    message: "This field is required",
                  }}
                  errors={errors}
                  onChange={(e: any) => {
                    setValue("gender", e.label);
                    setFormData((prevstate: any) => ({
                      ...prevstate,
                      gender: e.label,
                    }));
                  }}
                  defaultValue={
                    formData.gender
                      ? {
                          value: formData.gender,
                          label: formData.gender,
                        }
                      : null
                  }
                  disabled={false}
                />
              </Col>

              <Col md={6}>
                <InputText
                  type="text"
                  key={formData.panNumber}
                  name="panNumber"
                  label={t("PersonalInfo.Pan Number")}
                  placeholder="Enter Your PAN No"
                  register={{
                    ...register("panNumber", {
                      minLength: {
                        value: 10,
                        message: "PAN Number should not less than 10 digits.",
                      },
                      maxLength: {
                        value: 10,
                        message: "PAN Number should not more than 10 digits.",
                      },
                      pattern: {
                        value: CONSTANT_REGEX_SAMPLE.PAN,
                        message: "Invalid PAN Number.",
                      },
                    }),
                  }}
                  errors={errors}
                  onChange={handleFormDetails}
                  value={formData.panNumber}
                />
              </Col>

              <Col md={6}>
                <InputText
                  type="text"
                  key={formData.voterIdNumber}
                  name="voterIdNumber"
                  label={t("PersonalInfo.VoterId")}
                  placeholder="Enter your Voter Id No"
                  errors={errors}
                  register={{
                    ...register("voterIdNumber", {
                      minLength: {
                        value: 10,
                        message:
                          "Voter ID Number should not less than 10 digits.",
                      },
                      maxLength: {
                        value: 20,
                        message:
                          "Voter ID Number should not more than 20 digits.",
                      },
                    }),
                  }}
                  onChange={handleFormDetails}
                  value={formData.voterIdNumber}
                />
              </Col>

              <Col md={6}>
                <InputText
                  type="text"
                  name="drivingLicense"
                  label={t("PersonalInfo.DrivingLicenceNo")}
                  placeholder="Enter your Driving Licence No"
                  register={{
                    ...register("drivingLicense", {
                      validate: {
                        minLength: (value) =>
                          !value ||
                          value.length >= 13 ||
                          "Driving Licence No should not be less than 13 digits.",
                        maxLength: (value) =>
                          !value ||
                          value.length <= 13 ||
                          "Driving Licence No should not be more than 13 digits.",
                      },
                    }),
                  }}
                  errors={errors}
                  onChange={handleFormDetails}
                  value={formData.drivingLicense}
                  disabled={false}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.address}
                  type="text"
                  name="address"
                  label={t("Address")}
                  placeholder={t("Enter Address")}
                  register={{
                    ...register("address", {
                      required: "Please enter your  address",
                    }),
                  }}
                  disabled={false}
                  errors={errors}
                  onChange={handleFormDetails}
                  readOnly={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.gurdianName}
                  type="text"
                  name="gurdianName"
                  label={t("EditProfile.Guardian Name")}
                  placeholder="Enter Your Gurdian Name"
                  register={register("gurdianName")}
                  errors={errors}
                  defaultValue={formData?.gurdianName || ""}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.gurdianRelation}
                  type="text"
                  name="gurdianRelation"
                  label={t("EditProfile.Guardian Relation")}
                  placeholder="Enter Your Gurdian Relation"
                  register={register("gurdianRelation")}
                  errors={errors}
                  defaultValue={formData?.gurdianRelation || ""}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={"dob"}
                  type="date"
                  name="dob"
                  label={t("PersonalInfo.DOB")}
                  placeholder="Select date"
                  register={{
                    ...register("dob", {
                      required: "Please select the date",
                      // formatDate: "MM/dd/yyyy",
                    }),
                  }}
                  onChange={handleDate}
                  errors={errors}
                  disabled={false}
                  required={true}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.bloodGroup}
                  type="text"
                  name="bloodGroup"
                  label={t("EditProfile.Blood Group")}
                  placeholder="Enter Your Blood Group"
                  register={register("bloodGroup")}
                  errors={errors}
                  defaultValue={formData.bloodGroup || ""}
                />
              </Col>

              <Col md={6}>
                <InputText
                  key={formData?.identificationMark}
                  type="text"
                  name="identificationMark"
                  label="Identification Mark"
                  placeholder="Enter Your Identification Mark"
                  register={register("identificationMark")}
                  errors={errors}
                  defaultValue={formData.identificationMark || ""}
                />
              </Col>
            </Row>
          </Collapsible>
        </div>

        {/* ----------PROJECT SECTION-------- */}

        {formData?.rolesCode && (
          <div className="card mb-4 tiles_Size">
            <Collapsible
              key={2}
              title="Enterprise User Project Details"
              openStat={true}
            >
              {BLOCK_LEVEL_ROLES?.includes(formData?.rolesCode) && (
                <>
                  <Row>
                    <Col md={6}>
                      <InputMultiSelect
                        key={formData.districtName}
                        isMulti={false}
                        name="districtName"
                        label={t("AddressInfo.District")}
                        placeholder="Select your district"
                        register={control}
                        options={districts?.map((d: any) => {
                          return {
                            value: d.uuid,
                            label: d.districtName,
                          };
                        })}
                        rules={{
                          required: true,
                          message: "District is required",
                        }}
                        errors={errors}
                        onChange={handleDistrictChange}
                        defaultValue={
                          formData.districtName
                            ? {
                                value: formData.districtName,
                                label: formData.districtName,
                              }
                            : null
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <InputMultiSelect
                        key={formData.blockName}
                        isMulti={false}
                        name="blockName"
                        label={t("AddressInfo.Block")}
                        placeholder="Select your block"
                        register={control}
                        options={blocks?.map((b: any) => {
                          return { value: b.uuid, label: b.blockName };
                        })}
                        rules={{
                          required: true,
                          message: "Block is required",
                        }}
                        errors={errors}
                        onChange={handleBlockChange}
                        defaultValue={
                          formData.blockName
                            ? {
                                value: formData.blockName,
                                label: formData.blockName,
                              }
                            : null
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <InputMultiSelect
                        key={formData.panchayatName}
                        isMulti={false}
                        name="panchayatName"
                        label={t("AddressInfo.Panchayat")}
                        placeholder="Select your panchayat"
                        register={control}
                        options={panchayats?.map((p: any) => {
                          return {
                            value: p.uuid,
                            label: p.panchayatName,
                          };
                        })}
                        rules={{
                          required: true,
                          message: "Panchayat is required",
                        }}
                        errors={errors}
                        onChange={handlePanchayatChange}
                        defaultValue={
                          formData.panchayatName
                            ? {
                                value: formData.panchayatName,
                                label: formData.panchayatName,
                              }
                            : null
                        }
                      />
                    </Col>

                    <Col md={6}>
                      <InputMultiSelect
                        key={formData.projectName}
                        isMulti={false}
                        name="projectName"
                        label={t("ICDS Project Name")}
                        placeholder={t("Enter ICDS Project Name")}
                        register={control}
                        options={projectDetailsList?.map((p: any) => {
                          return {
                            value: p.projectCode,
                            label: p.projectName,
                          };
                        })}
                        rules={{
                          required: true,
                          message: "Project is required",
                        }}
                        errors={errors}
                        onChange={handleProjectChange}
                        defaultValue={
                          formData.districtName
                            ? {
                                value: formData.projectName,
                                label: formData.projectName,
                              }
                            : null
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}

              {BANK_LEVEL_ROLES?.includes(formData?.rolesCode) && (
                <>
                  <Row>
                    <Col md={6}>
                      <InputMultiSelect
                        key={formData.bankName}
                        isMulti={false}
                        name="bankName"
                        label={t("LoanDetails.Bank Name")}
                        placeholder="Select your Bank Name"
                        register={control}
                        options={bankList}
                        rules={{
                          required: true,
                          message: "Bank is required",
                        }}
                        errors={errors}
                        onChange={handleBankSelect}
                        defaultValue={
                          formData.bankName
                            ? {
                                value: formData.bankName,
                                label: formData.bankName,
                              }
                            : null
                        }
                      />
                    </Col>
                    <Col md={6}>
                      <InputMultiSelect
                        key={formData.ifscCode}
                        isMulti={false}
                        name="ifscCode"
                        label={t("LoanDetails.IFSC Code")}
                        placeholder="Select your IFSC Code"
                        register={control}
                        options={ifscList}
                        rules={{
                          required: true,
                          message: "IFSC Code is required",
                        }}
                        errors={errors}
                        onChange={(e: any) => {
                          setFormData((prevstate: any) => ({
                            ...prevstate,
                            ifscCode: e?.label,
                          }));
                          setValue("ifscCode", e.label);
                        }}
                        defaultValue={
                          formData.ifscCode
                            ? {
                                value: formData.ifscCode,
                                label: formData.ifscCode,
                              }
                            : null
                        }
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Collapsible>
          </div>
        )}

        <Col md={6} style={{ paddingLeft: "1rem" }}>
          <InputCheck
            type="checkbox"
            name="active"
            label=""
            register={{
              ...register("active", {
                onChange: onChangeCheckBox,
              }),
            }}
            errors={errors}
            options={checkOptions}
          />
        </Col>
        <div style={{ textAlign: "center" }}>
          <Button
            className="btn background-saffron"
            type="submit"
            style={{ width: "max-content" }}>
            {formData?.userId ? (
              <>{t("login.Update")}</>
            ) : (
              <>{t("login.Submit")}</>
            )}
          </Button>
        </div>
      </form>
    </>
  );
};
export default UserManageForm;
