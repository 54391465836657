import React from 'react';
import './MissonSaktiForm.css'; // Assuming you're using an external CSS file

const VerificationCertificate = () => {
    return (
        <div className="certificate-container">
            <div className="certificate-title">
                Block/Project Level Verification-cum-Recommendation Certificate from <br/>
                Department of Mission Shakti
            </div>
            <br/>
            <div className="certificate-content">
                <p className="paragraph-spacing" style={{ textIndent: '40px' }}>
                    I/We hereby certify that Miss/Mrs. ......................................................................... W/o or D/o 
                    ........................................presently residing in village/town .......................................................... is working as a <u>Federation Executive Member / Community Support Staff/ Women SHG member as BCA</u> in the capacity of (designation)  
                    ................................................................. since the year ............................ in (Name of the GPLF/BLF/DLF) ................................................................. under Department of Mission Shakti.
                </p>
                
                <p className="paragraph-spacing" style={{ textIndent: '40px' }}>
                    Further, it is to certify that the statements/particulars in this application and the documents submitted 
                    (as per list attached) are true, complete and correct to the best of my knowledge and belief.
                </p>
                
                <p className="paragraph-spacing" style={{ textIndent: '40px' }}>
                    Considering the request for two-wheeler of the applicant, I/We recommend a loan amount of Rs.  ______________________ 
                    (Rupees .................................................. only) in favor of Mrs. ........................................  towards 
                    purchase of Scooter (Model and Make name) ............................... under Mission Shakti Scooter Yojana - 
                    "Interest Free Loan for purchase of Scooter by Mission Shakti Federation Leaders and Community Support Staff."
                </p>
            </div>

            <div className="signature">
                <p style={{fontWeight: 'bold'}}>Signature</p>
                <p>Recommending Letter</p>
                <p>Name</p>
                <p>Designation</p>
                <p>Block/Project</p>
                <p>Seal & Signature</p>
            </div>
        </div>
    );
}

export default VerificationCertificate;
