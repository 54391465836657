import React, { useState } from "react";
import "./Register.css";
import {  useForm } from "react-hook-form";
import InputText from "../common/InputText";
import { Row, Col } from "react-bootstrap";
import { CONSTANT_REGEX_SAMPLE } from "../../utils/constants";
import { useNavigate, Link } from "react-router-dom";
import CustomCaptcha from "../common/CustomCaptcha";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { userRegister } from "../../services/http.service";
import { useTranslation } from 'react-i18next';
import { useLanguageContext } from '../../LanguageContext';
import LogoOdisha from "../../assets/images/OdishaLogo2.png"
import LScooter from "../../assets/images/scooterImage2.jpg"

const Register = () => {
    const { t } = useTranslation();
    const { changeLanguage } = useLanguageContext();
    let navigate = useNavigate();
    const [isCaptchaValid, setIsCaptchaValid] = useState(false);
    const {
        register,
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: "dd",
            password: "",
            gender: "",
            department: { value: "Physics", label: "Physics" },
            language: ["c", "js"],
            comment: "",
            file: "",
            name: "",
            adhar_no: "",
            mobile_no: "",
            shgId : "",
        }
    });

    const onSubmit = async (data: any) => {
        if (!isCaptchaValid) {
            toast.error('Please complete the CAPTCHA.', {
                position: "top-right"
            });
            return;
        }

        try {
            const response = await userRegister("/_user/_create", {
              nameAsPerAadhar: data.name,
              mobileNumber: data.mobile_no,
              aadharNumber: data.adhar_no,
              shgDetails: {
                shgId: data?.shgId,
              },
              // captchaResponse: '' // Add your captcha response here if applicable
            });
            console.log('Response', response);
            // localStorage.setItem('token', JSON.stringify(response.data.token));
            toast.success('Registration successful!', {
                position: "top-right"
            });
            setTimeout(() => {
                navigate('/mission-shakti/login');
            }, 1000);
        } catch (error:any) {
            let errMsg:any  = error?.response?.data?.message ?  error?.response?.data?.message : 'Registration failed. Please try again.';
            toast.error(errMsg, {
                position: "top-right"
            });
            console.error('There was an error!', error);
        }
    };


    return (
        <>
            <div className="register justify-content-center align-items-center">
                <div className="card mt-3 mb-3" style={{borderRadius: "20px", boxShadow: "0px 4px 7px 0px #00000040"}}>
                    <div className="card-body row">
                        <div className="col-sm-6" style={{background: "#FF6402", paddingLeft:0, paddingRight:0,  borderRadius: "20px",boxShadow: "0px 4px 7px 0px #00000040"}}>
                            <div className="reg-left-panel">
                                <div><img src={LogoOdisha} alt="Odisha Logo" style={{width: "30%"}} /></div>
                                <div className="reg-text">Mission Shakti</div>
                                <div className="reg-text">Scooter Yojana</div>
                                <div className="font-s-18">Government of Odisha</div>
                            </div>
                            <div>
                                    <img src={LScooter} alt="Scooter" className="reg-scooter-image"/>
                           </div>
                        </div>
                        <div className="col-sm-6">
                            <form onSubmit={handleSubmit(onSubmit)} style={{padding: "0px 20px 0px 10px", marginTop: "10px"}}>
                            <h2 className="text-center mb-4">{t('Register.Register1')}</h2>

                                <InputText type="text" name="name" label={t('Register.AadharName')} placeholder={t('Register.AadharName')} register={{
                                    ...register("name", {
                                        required: "Please enter your name",
                                        // onChange: onChangeName,
                                        //   pattern: {
                                        //     value: CONSTANT_REGEX_SAMPLE.EMAIL,
                                        //     message: "Please enter a valid email"
                                        //   }
                                    })
                                }} errors={errors}
                                />
                                <InputText type="text" name="adhar_no" label={t('Register.AadharNo')} placeholder={t('Register.AadharNo')} register={{
                                    ...register("adhar_no", {
                                        required: "Please enter your Aadhaar number",
                                        minLength: {
                                            value: 12,
                                            message: "Aadhaar Number should not less than 12 digits."
                                        },
                                        maxLength: {
                                            value: 12,
                                            message: "Aadhaar Number should not more than 12 digits."
                                        },
                                        pattern: {
                                            value: CONSTANT_REGEX_SAMPLE.NUMERIC,
                                            message: "Aadhaar Number should be numeric."
                                        }

                                        // pattern: {
                                        //     value: /^[0-9]$/,
                                        //     message: "Password should contain numeric characters"
                                        // }
                                        //   pattern: {
                                        //     value: CONSTANT_REGEX_SAMPLE.PASSWORD,
                                        //     message: "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol"
                                        //   }
                                    })

                                }} errors={errors} />
                                <InputText type="text" name="mobile_no" label={t('Register.MobileNo')} placeholder={t('Register.MobileNo')} register={{
                                    ...register("mobile_no", {
                                        required: "Please enter your Mobile number",
                                        minLength: {
                                            value: 10,
                                            message: "Mobile Number should not less than 10 digits."
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "Mobile Number should not more than 10 digits."
                                        },
                                        //   pattern: {
                                        //     value: CONSTANT_REGEX_SAMPLE.PASSWORD,
                                        //     message: "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol"
                                        //   }
                                    })
                                }} errors={errors} />


                            <InputText type="text" name="shgId" label={t('Register.SHG_Id')} placeholder={t('Register.SHG_Id')} register={{
                                    ...register("shgId", {
                                        required: "Please enter your SHG ID",
                                        // minLength: {
                                        //     value: 6,
                                        //     message: "SHG ID should not less than 6 chars."
                                        // },
                                        // maxLength: {
                                        //     value: 10,
                                        //     message: "SHG ID should not more than 20 chars."
                                        // },
                                        //   pattern: {
                                        //     value: CONSTANT_REGEX_SAMPLE.PASSWORD,
                                        //     message: "Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol"
                                        //   }
                                    })
                                }} errors={errors} />
                                <CustomCaptcha onCaptchaValid={setIsCaptchaValid} />
                                <button className="generic-btn-submit">{t('Register.Submit')}</button>
                                <button className="generic-btn-cancel" onClick={() => navigate('/mission-shakti/')}>{t('Register.Cancel')}</button>
                                
                                <Row className="mt-3">
                                    <Col className="text-center font-s-14">
                                        <div style={{display: "inline"}}>{t('Already have an account? ')}</div><Link to="/mission-shakti/login">Login</Link>
                                    </Col>
                                </Row>
                            </form>
                        </div>
                        
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    )
}
export default Register