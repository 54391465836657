import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";

const userRole = localStorage.getItem("role");

interface FileUploadProps {
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?: any; // Adjust this type as per your actual register type
  accept?: string;
  disabled?: boolean;
  errors?: any;
  name?: any;
  label?: any;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<string | null>(
    props?.value
      ? `${process.env.REACT_APP_FILE_DOWNLOAD}=${props.value}`
      : null
  );
  const [fileName, setFileName] = useState("");
  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setFile(reader.result as string);
      };

      reader.readAsDataURL(selectedFile);
      setFileName(selectedFile.name);

      // reader.readAsDataURL(selectedFile);
      if (props.onChange) {
        props.onChange(e);
      }
    }
  };

  const downloadDoc = async () => {
    if (!file) {
      console.error("No file to download");
      return;
    }
    try {
      const a = document.createElement("a");
      a.href = file;
      a.download = "file";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Form.Control
        type="file"
        {...props?.register}
        onChange={handleUpload}
        multiple
        accept={props?.accept}
        style={{
          pointerEvents: userRole === "CSS" ? "" : "none",
          display: "none",
        }}
        disabled={props?.disabled}
        ref={fileInputRef}
      />
      <label>{props?.label}</label>
      <div
        style={{
          borderRadius: "8px",
          backgroundColor: "#fff",
          padding: "0.85rem",
          display: "flex",
          gap: "1.2rem",
          width: "fit-content",
          alignItems: "center",
          border: "1px solid #ccc",
        }}
      >
        {file ? (
          <>
            {file && !fileName && (
              <FaDownload
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => {
                  downloadDoc();
                }}
              />
            )}
            {fileName && <span>{fileName}</span>}
          </>
        ) : (
          <span>Choose File</span>
        )}
        <button
          onClick={(e) => {
            e.preventDefault();
            fileInputRef?.current?.click();
          }}
          style={{
            border: "none",
            borderRadius: "8px",
            padding: "0.5rem 1rem",
            cursor: "pointer",
            fontSize: "12px",
          }}
        >
          Browse Image
        </button>
      </div>

      {props?.errors[props?.name] && (
        <p className="errorMsg" style={{ color: "red" }}>
          This field is required
        </p>
      )}
    </div>
  );
};

export default FileUpload;
