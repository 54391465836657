import React, { useContext, useEffect, useState } from "react";
import ActionTable from "../../common/ActionTable";
import { useNavigate } from "react-router-dom";
import { NOTIFICATION_COLUMN_DATA } from "./HomeConst";
import { getApplications, postData } from '../../../services/http.service'; // Import your API service
import { ApplicationContext } from "../../ApplicationContext";
import { useTranslation } from "react-i18next";
import { timestampToDateFormat } from "../../../utils/utils";
import { Button, Col, Row } from "react-bootstrap";
import InputText from "../../common/InputText";
import { useForm } from "react-hook-form";
import InputMultiSelect from "../../common/InputMultiSelect";
import { toast, ToastContainer } from "react-toastify";
import { APP_MESSAGES } from "../../../utils/constants";
import { URLS_ENDPOINT } from "../../../utils/urls.const";
import AdminAnalyticsView from "./admin-analytics-view";

const defFilterFormData: any = {
    applicationNumbers: "",
    districtIds: "",
  };

const Home = () => {
    const { t } = useTranslation();
    // console.log("Value----" , t)
    const columns = NOTIFICATION_COLUMN_DATA;
    // columns.forEach((column) => {
    //     column.colName = t(Table.colName);
    // })
    const [rowData, setRowData] = useState([]);
    const navigate = useNavigate();
    const { application, updateApplicationContext } =
    useContext(ApplicationContext);

    const masterBaseURL: any = process.env.REACT_APP_MASTER_SERVICE;
    const masterServiceEndPoint: any = URLS_ENDPOINT?.MASTER_SERVICES;
    const [filterFormData, setfilterFormData] = useState(defFilterFormData);
    const {
      register,
      handleSubmit,
      control,      
      reset,     
      formState: { errors },
  } = useForm({
      defaultValues: defFilterFormData
  });
  const [districts, setDistricts] = useState([]);

    // const formatDate = (timestamp: string | number | Date) => {
    //     const date = new Date(timestamp);
    //     return date.toLocaleDateString(); // You can customize the format
    // };

    // Function to fetch work order apllications list from API 
    const fetchWorklistApplications = async () => {
      try {
        const token = localStorage.getItem("token");
        const userInfo = JSON.parse(
          localStorage.getItem("UserDetails") || "{}"
        );

        if (token && userInfo) {
          console.log(userInfo.roles[0].roleName, "roles");
          const dashboardApplications = await getApplications(token, {
            // userInfo
            uuid: userInfo.uuid,
            rolesCode: userInfo.roles[0].roleName,
            // userInfo
          });

          const formattedData = dashboardApplications.map((item: any) => {
            const district = item.district || "";
            const block = item.block || "";
            const panchayat = item.panchayat || "";

            const jurisdiction = [district, block, panchayat]
              .filter((part) => part)
              .join("/");

            return {
              ...item,
              // submittedDate: formatDate(item.submittedDate),
              // createdDate: formatDate(item.createdDate),
              jurisdiction: jurisdiction,
            };
          });

          setRowData(formattedData);
        } else {
          console.error("User not authenticated");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    // Function to fetch ALL apllications list for ADMIN 
    const fetchAllApplicationsList = async (district?:string,appNo?:string) => {
      try {
        const token = localStorage.getItem("token");
        const userInfo = JSON.parse(
          localStorage.getItem("UserDetails") || "{}"
        );

        if (token && userInfo) {
          console.log(userInfo?.roles[0]?.roleName, "roles");
          const response: any = await postData(
            process.env.REACT_APP_APPLICATION_SEARCH,
            {
              uuids: [],
              applicationNumbers: appNo ? [appNo] : [],
              districtIds: district ? [district] : [],
            }
          );

          console.log(response);

          if (response?.data && response?.data?.length >0) {

            const formattedData = response?.data?.map((item: any) => {

                const appDetails = item?.applicationBasicDetails[0];
                if(appDetails){
                    const district = appDetails?.districtId || "";
                    const block = appDetails?.blockId || "";
                    const panchayat = appDetails?.panchayatId || "";
                    const jurisdiction = [district, block, panchayat]
                      .filter((part) => part)
                      .join("/");
        
                    return {
                      ...appDetails,
                      id: appDetails?.uuid || null, 
                      nameOfApplicant : appDetails?.applicantNameAsPerAadhar || "",
                      submittedDate : appDetails?.auditDetails?.createdTime ?  timestampToDateFormat(appDetails?.auditDetails?.createdTime) : "",
                      jurisdiction: jurisdiction,
                    };
                }

              });
            setRowData(formattedData);
          }else{
            setRowData([]);
            toast.info(APP_MESSAGES.NO_DATA_MSG, {
                position: "top-right",
              });
          }


        } else {
          console.error("User not authenticated");
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    

    const onSubmitFilterForm = async (e:any) => {
        try {
            console.log(e);
            fetchAllApplicationsList(e?.districtIds?.label, e?.applicationNumbers);
        } catch (error) {
          toast.error(APP_MESSAGES.CMN_ERR, {
            position: "top-right",
          });
          console.error("Error fetching bank list:", error);
        }
      };

    const handleFilterReset = async (e:any) => {
        setfilterFormData(defFilterFormData);
        fetchAllApplicationsList();
    }

    const role = localStorage.getItem("role");
    useEffect(() => {

        async function loadDistricts() {
            const response: any = postData(
              masterBaseURL + masterServiceEndPoint?.ADDRESS_DROPDOWN
            );
    
            response
              .then((res: any) => {
                const { state, districts } = res?.data?.addressDetails;
                setDistricts(districts);
              })
              .catch((err: any) => {
                toast.error(err?.message, {
                  position: "top-right",
                });
              });
          }
      
      role && role === "ADMIN"
        ? fetchAllApplicationsList()
        : fetchWorklistApplications();

        loadDistricts(); 
    }, []);

    const handleNotificationRowClick = async (rowData: any) => {
        // try {
        //     // Add your row click handling logic here
        // } catch (error) {
        //     navigate('/mission-shakti/');
        // }

        if(rowData?.id){
          updateApplicationContext({
            id: rowData.id,
            applicationNumber: rowData.applicationNumber,
          });
        navigate(
            `/mission-shakti/mmsy_loan_form?id=${rowData?.id}`
          );
        }

    };

    const onCheckHandler = (e:any)=>{
        console.log("onCheckHandler==",e)
    }


    return (
      <div className="w-full">
        {/* This is Home */}
        <ToastContainer />
        <div>
          {role === "ADMIN" && (
            <>
              <AdminAnalyticsView />
                <br/>
              <form onSubmit={handleSubmit(onSubmitFilterForm)}>
                <div className="card">
                  <div className="card-body">
                    <Row>
                      <Col md={4}>
                        <InputMultiSelect
                          key={filterFormData.districtIds}
                          isMulti={false}
                          name="districtIds"
                          label={t("AddressInfo.District")}
                          placeholder="Select your district"
                          register={control}
                          options={districts?.map((d: any) => {
                            return {
                              value: d.uuid,
                              label: d.districtName,
                            };
                          })}
                          errors={errors}
                          onChange={(e: any) => {
                            setfilterFormData((prevstate: any) => ({
                              ...prevstate,
                              districtIds: e,
                            }));
                          }}
                          defaultValue={
                            filterFormData?.districtIds
                              ? {
                                  ...filterFormData?.districtIds,
                                }
                              : null
                          }
                        />
                      </Col>

                      <Col md={4}>
                        <InputText
                          key={filterFormData?.applicationNumbers}
                          type="text"
                          name="applicationNumbers"
                          label={t("Application No.")}
                          placeholder={t("Enter Application No.")}
                          register={register("applicationNumbers")}
                          errors={errors}
                          defaultValue={
                            filterFormData?.applicationNumbers || ""
                          }
                        />
                      </Col>

                      <Col md={4}>
                        <Button
                          className="btn background-saffron"
                          type="submit"
                          style={{ marginTop: "1rem", width: '5rem' }}
                        >
                          Filter
                        </Button>

                        <Button
                          className="btn btn-reset"
                          type="reset"
                          onClick={handleFilterReset}
                          style={{ marginTop: "1rem", marginLeft: '1rem' }}
                        >
                          Reset
                        </Button>

                      </Col>
                    </Row>
                  </div>
                </div>
              </form>
            </>
          )}
          <ActionTable
            columns={columns}
            rowData={rowData}
            tableHeader={"All Applications List"}
            additionalMethod={handleNotificationRowClick}
            // checkType='multiCheck'
            // onCheckHandler={onCheckHandler}
            searchBarPlaceholder={t("Table.SEARCH_BY")}
            hideColumns={["daysPendingSince", "action"]}
          />
        </div>
      </div>
    );
}

export default Home;


